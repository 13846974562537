import { clsx, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import { RegrelloCommandItem, RegrelloIcon, RegrelloIconName, useCommandState } from "@regrello/ui-core";
import {
  AddOptionToRegrello,
  CreateNewFieldEmpty,
  CreateNewFieldNotAllowed,
  PermissionsV2CreateNewFieldNotAllowed,
} from "@regrello/ui-strings";
import { ReactNode, useMemo } from "react";

import { FeatureFlagService } from "../../../../../services/FeatureFlagService";

interface RegrelloSelectV2AddOptionProps {
  /**
   * Whether add option is allowed. Controlling styling and disabled state.
   */
  allowCreateOptions?: boolean;

  iconName?: RegrelloIconName;

  /**
   * Custom message to display.
   */
  message?: ReactNode | ((inputValue: string) => ReactNode);

  /**
   * Invokes this callback on select with input value passed as parameter.
   */
  onSelect?: (inputValue: string) => void;
}

/**
 * This component can be used in `extraOptions` of `RegrelloFormFieldSelect` to render `add` button with access to the `input` state.
 */
export const RegrelloSelectV2AddOption = ({
  allowCreateOptions,
  iconName,
  message,
  onSelect,
}: RegrelloSelectV2AddOptionProps) => {
  const searchInput = useCommandState((state) => state.search);

  let text: ReactNode = CreateNewFieldEmpty;

  if (message != null) {
    if (typeof message === "function") {
      text = message(searchInput);
    } else {
      text = message;
    }
  } else {
    if (allowCreateOptions && searchInput != null && searchInput !== EMPTY_STRING) {
      text = AddOptionToRegrello({ name: <strong>{searchInput}</strong> });
    }

    if (!allowCreateOptions) {
      text = FeatureFlagService.isEnabled(FeatureFlagKey.PERMISSIONS_V2_2024_01)
        ? PermissionsV2CreateNewFieldNotAllowed
        : CreateNewFieldNotAllowed;
    }
  }

  const textElement = useMemo(() => {
    if (iconName != null) {
      return (
        <div className={clsx("flex gap-2", { "text-primary-textMuted": allowCreateOptions })}>
          <RegrelloIcon iconName={iconName} />
          {text}
        </div>
      );
    }

    return <span className={clsx({ "text-primary-textMuted": allowCreateOptions })}>{text}</span>;
  }, [allowCreateOptions, iconName, text]);

  return (
    <RegrelloCommandItem
      data-testid={DataTestIds.FORM_FIELD_SELECT_OPTION}
      disabled={!allowCreateOptions}
      forceMount={true}
      onSelect={() => {
        onSelect?.(searchInput);
      }}
      selected={false}
      text={textElement}
      value="__CREATE_NEW_FIELD__"
    />
  );
};
