import { assertNever, EMPTY_ARRAY, EMPTY_STRING, isDefined } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import { FeatureFlagKey } from "@regrello/feature-flags-api";
import {
  ActionItemStatus,
  ConditionConnective,
  CustomFieldDefaultColumnOption,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceValueInputType,
  SpectrumFieldVersionFields,
  useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery,
  useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery,
  useFieldsQueryLazyQuery,
  useLatestSpectrumFieldVersionsV2QueryLazyQuery,
} from "@regrello/graphql-api";
import { RegrelloIcon, RegrelloTooltipV4, RegrelloTypography } from "@regrello/ui-core";
import {
  Add,
  AddFieldToBlueprint,
  AddFieldToParent,
  AddFieldToWorkflow,
  ConfigureFieldHelperText,
  CreateNewFieldEmpty,
  DialogActionNext,
  FromPreviousHelperText,
  FromPreviousTasks,
  FromWorkflow,
  Stage,
} from "@regrello/ui-strings";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { RegrelloFormFieldBaseProps } from "./_internal/RegrelloFormFieldBaseProps";
import { RegrelloFormFieldSelectOption } from "./_internal/selectOptions/RegrelloFormFieldSelectOption";
import { RegrelloSelectV2AddOption } from "./_internal/selectOptions/RegrelloSelectV2AddOption";
import { RegrelloSelectV2ErrorOption } from "./_internal/selectOptions/RegrelloSelectV2ErrorOption";
import {
  RegrelloFormFieldSelectPropsV2,
  RegrelloFormFieldSelectV2,
  RegrelloSelectChangeReason,
} from "./RegrelloFormFieldSelectV2";
import { FeatureFlagService } from "../../../services/FeatureFlagService";
import { FieldInstanceFieldsWithSource } from "../../../types/FieldInstanceFieldsWithSource";
import { getFieldInstancesWithSourcesComparator } from "../../../utils/comparators/fieldInstancesWithSourcesComparator";
import {
  getCustomFieldSource,
  getCustomFieldSourceName,
  hydrateSourcesInFieldInstances,
} from "../../../utils/customFields/customFieldSourceUtils";
import { getCustomFieldInstanceInputType } from "../../../utils/customFields/getCustomFieldInstanceInputType";
import { getFieldInstanceId } from "../../../utils/customFields/getFieldInstanceId";
import { isQueryResultError, isQueryResultLoading } from "../../../utils/graphqlUtils";
import { useDocumentUploadStatus } from "../../../utils/hooks/useDocumentUploadStatus";
import { useErrorHandler } from "../../../utils/hooks/useErrorHandler";
import { RegrelloFormDialogName } from "../../../utils/sentryScopeUtils";
import { RegrelloFormDialog } from "../../atoms/dialog/RegrelloFormDialog";
import { CreateFieldDialog } from "../../views/modals/formDialogs/customFields/createFieldDialog/CreateFieldDialog";
import {
  getSpectrumFieldOrLegacyFieldViaFF,
  RegrelloConfigureCustomFieldsForm,
} from "../../views/modals/formDialogs/customFields/RegrelloConfigureCustomFieldsForm";
import { RegrelloConfigureSpectrumFormsFormFields } from "../../views/modals/formDialogs/customFields/useConfigureSpectrumForms";
import { DocumentFieldPlugin } from "../customFields/plugins/DocumentFieldPlugin";
import { CustomFieldPluginRegistrar } from "../customFields/plugins/registry/customFieldPluginRegistrar";
import { CustomFieldPlugin } from "../customFields/plugins/types/CustomFieldPlugin";
import { getFieldInstanceFieldsFromAcyclicFieldInstanceFields } from "../customFields/plugins/utils/getFieldInstanceFieldsFromAcyclicFieldInstanceFields";
import { SpectrumFieldPluginRegistrar } from "../spectrumFields/registry/spectrumFieldPluginRegistrar";
import { SpectrumFieldValidationType } from "../spectrumFields/types/SpectrumFieldValidationType";

function createSpecialOptionWithIdAndMaybeField(id: number, field?: FieldFields): FieldInstanceFields {
  // (clewis): This is all we need:
  return {
    field: field ?? { name: "" },
    values: [{ id }],
  } as unknown as FieldInstanceFields;
}

const FIELD_OPTION_ID = -1;
const FIELD_OPTION_GROUP_NAME = "addFieldToParent";

export type RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext =
  | {
      type: "workflowTemplate";
      workflowTemplateId: number;
      workflowStageTemplateId: number | undefined;
      dependingOnWorkflowStageTemplateId: number | undefined;
    }
  | {
      type: "workflow";
      workflowId: number;
      workflowStageId: number | undefined;
      dependingOnWorkflowStageId: number | undefined;
    };

/** Callback invoked when a field option is selected. */
export type CustomFieldInstanceSelectFieldOptionCallback = (field: FieldFields, value: unknown) => void;

/** Props for configuring a custom field by providing a value if the user selects a field option. */
export type CustomFieldInstanceSelectConfigureFieldProps = {
  /**
   * Description to use for the configure custom fields form.
   * @default "This field will be added to the workflow. Please provide a value below."
   */
  description?: string;

  /** Whether the current user is allowed to create users. */
  isCreatingUsersAllowed: boolean;

  /** Whether the field being configured is multi-valued. */
  isMultiValued?: boolean;

  /** Projection for the field being configured if it's a Regrello object. */
  projection?: {
    selectedRegrelloObjectPropertyIds: number[];
  };

  /**
   * Title to use for the configure custom fields form.
   * @default "Add field"
   */
  title?: string;
};

/**
 * Configuration for supporting field options in the select dropdown. If defined, the provided or
 * loaded field options will be rendered (wrapped in a mock field instance) after any field
 * instance options. It's on the caller to properly handle a selected field option.
 */
export type CustomFieldInstanceSelectFieldOptionsConfigBase = {
  /**
   * If defined, when creating a field the multiplicity switch, if the field type supports it, will
   * be disabled with this text rendered below.
   */
  allowMultipleSwitchDisabledHelperText?: string;

  /**
   * If defined, the user will be prompted to provide a value when a field option is selected.
   * The form will be configured with the provided props.
   */
  configureFieldProps?: CustomFieldInstanceSelectConfigureFieldProps;

  /**
   * Initial value for the multiplicity switch when creating a field, if the field type supports the
   * option.
   */
  defaultAllowMultiple?: boolean;

  /** Callback invoked to filter the loaded field options. */
  filterOptions?: (option: FieldFields) => boolean;

  /** Whether creating new fields from the select dropdown is allowed. */
  isCreatingFieldsAllowed: boolean;

  /** Name of the current workflow or blueprint. Used in the field options group label. */
  workflowOrBlueprintName?: string;
};

export interface RegrelloFormFieldCustomFieldInstanceSelectProps
  extends RegrelloFormFieldBaseProps<FieldInstanceFields | null>,
    Pick<
      RegrelloFormFieldSelectPropsV2<FieldInstanceFields>,
      "inputRef" | "onChange" | "onClearClick" | "value" | "placeholder" | "getOptionDisabled"
    > {
  /**
   * An allow list for filtering which field plugins this field should allow the user to select.
   */
  allowedFieldPlugins?: Array<CustomFieldPlugin<unknown>>;

  /**
   * Configuration for supporting field options in the select dropdown. If defined, the provided or
   * loaded field options will be rendered (wrapped in a mock field instance) after any field
   * instance options. It's on the caller to properly handle a selected field option.
   */
  fieldOptionsConfig?: CustomFieldInstanceSelectFieldOptionsConfigBase & {
    onFieldOptionSelected: CustomFieldInstanceSelectFieldOptionCallback;
    // Filter spectrum fields, by validation type for performance reasons.
    spectrumFieldValidationType?: SpectrumFieldValidationType;
  };

  /** An optional filter to be applied to the final set of options. */
  filterCondition?: (option: FieldInstanceFields) => boolean;

  /**
   * Callback to render a tooltip for a field instance option. If defined, return "" to not render a
   * tooltip for the option.
   */
  getOptionTooltip?: (option: FieldInstanceFields) => string;

  /**
   * Whether an empty optional field on a completed task is enabled in the select field.
   * @default false
   */
  isAllowEmptyOptionalFieldInstances?: boolean;

  /**
   * Whether to hide inactive field instances (i.e., their respective fields have been deleted)
   * in the field instance select dropdown.
   */
  isInactiveFieldInstancesHidden?: boolean;

  /**
   * Whether selected optional fields retain their `OPTIONAL` input type or are converted to
   * `REQUESTED` input type.
   * @default false
   */
  isRetainOptionalFieldInstanceInputType?: boolean;

  /**
   * Whether to show helper text for each field instance coming from previous tasks that indicates
   * the source task name. Useful for distinguishing between fields with the same name.
   * @default false
   */
  isSourceHelperTextVisible?: boolean;

  /**
   * A list of options that should not be displayed in the suggestions menu. Useful for preventing
   * already-selected fields from being selected again, for example.
   */
  omittedOptions?: FieldInstanceFields[];

  optionsConfig:
    | { type: "hardcoded"; options: FieldInstanceFields[] }
    | {
        type: "asyncLoaded";

        /** The ID of the action item on which the current action item depends, if any. */
        // (clewis): Required so that callers don't forget to provide it.
        dependingOnActionItemTemplateId: number | undefined;

        /**
         * Information about the context in which this field is shown. Affects which field instances will
         * be displayed from the workflow level.
         */
        workflowContext: RegrelloFormFieldCustomFieldInstanceSelectWorkflowContext;

        isAutomatedTask?: boolean;

        /** Only displays instances of a given field. */
        fieldIds?: number[];
      };
}

/**
 * A single-select field in which the user can select from all field instances available in the
 * current workflow workflowContext. This includes workflow-level field instances as well as field instances
 * from earlier tasks in the workflow.
 */
export const RegrelloFormFieldCustomFieldInstanceSelect = React.memo<RegrelloFormFieldCustomFieldInstanceSelectProps>(
  function RegrelloFormFieldCustomFieldInstanceSelectFn({
    allowedFieldPlugins,
    className,
    fieldOptionsConfig,
    filterCondition,
    getOptionDisabled,
    getOptionTooltip,
    isAllowEmptyOptionalFieldInstances = false,
    isInactiveFieldInstancesHidden = false,
    isSourceHelperTextVisible = false,
    omittedOptions,
    onChange, // (clewis): Pull this out because we need to override it.
    optionsConfig,
    ...selectProps
  }) {
    const isSpectrumEnabled = FeatureFlagService.isEnabled(FeatureFlagKey.SPECTRUM_2023_08);

    const { handleError } = useErrorHandler();

    const [loadedOptions, setLoadedOptions] =
      useState<Array<FieldInstanceFields | FieldInstanceFieldsWithSource>>(EMPTY_ARRAY);
    const filteredLoadedOptions = useMemo(
      () =>
        loadedOptions.filter(
          (fiv) =>
            optionsConfig?.type !== "asyncLoaded" ||
            optionsConfig?.fieldIds == null ||
            optionsConfig.fieldIds.includes(fiv.field.id),
        ),
      [loadedOptions, optionsConfig],
    );

    // (hchen): Spectrum form is unrelated to this component, but must be initialized so for
    // `RegrelloConfigureCustomFieldsForm.Component` to work
    const configureSpectrumFormManager = useForm<RegrelloConfigureSpectrumFormsFormFields>({
      mode: "onChange",
      defaultValues: {
        forms: EMPTY_ARRAY,
      },
    });

    // State variables that enable the UX nicety of preloading the create blueprint dialog with the
    // currently inputted search query.
    const [defaultValueForCreateFieldDialog, setDefaultValueForCreateFieldDialog] = useState<string>("");
    const [isCreateFieldDialogOpen, setIsCreateFieldDialogOpen] = useState<boolean>(false);

    const [getFieldInstancesAvailableAsSourceValuesInWorkflowAsync, workflowResult] =
      useFieldInstancesAvailableAsSourceValuesInWorkflowQueryLazyQuery({
        onError: (error) => handleError(error),
        fetchPolicy: "no-cache",
      });

    const [getFieldInstancesAvailableAsSourceValuesInWorkflowTemplateAsync, workflowTemplateResult] =
      useFieldInstancesAvailableAsSourceValuesInWorkflowTemplateQueryLazyQuery({
        onError: (error) => handleError(error),
        fetchPolicy: "no-cache",
      });

    const [getSpectrumFieldsAsync, spectrumFieldsResult] = useLatestSpectrumFieldVersionsV2QueryLazyQuery({
      onError: (error) => handleError(error),
      fetchPolicy: "no-cache",
      variables: {
        params: {
          excludeControllerFields: true,
        },
        filters: fieldOptionsConfig?.spectrumFieldValidationType
          ? {
              filters: [
                {
                  key: CustomFieldDefaultColumnOption.PLUGIN,
                  value: fieldOptionsConfig.spectrumFieldValidationType,
                },
              ],
              connector: ConditionConnective.AND,
            }
          : undefined,
      },
    });

    const [getFieldsAsync, fieldsResult] = useFieldsQueryLazyQuery({
      onError: (error) => handleError(error),
      fetchPolicy: "no-cache",
    });

    const fieldInstancesFromParent: FieldInstanceFields[] = useMemo(() => {
      if (optionsConfig.type === "hardcoded") {
        return EMPTY_ARRAY;
      }

      const { workflowContext } = optionsConfig;

      switch (workflowContext.type) {
        case "workflowTemplate": {
          // (zstanik): `fieldInstancesAvailableAsSourceValuesInWorkflowTemplate` was updated to
          // return base field instances to fix a query loop caused by non-normalized field instance
          // values (which can't be normalized at the moment without a much larger lift) getting
          // replaced by slightly different data in a loop. These particular field instances don't
          // need to be full `FieldInstanceFields`, but convert them to stubbed
          // `FieldInstanceFields` first to make them compatible with child components below.
          const data = workflowTemplateResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate;
          return (data?.workflowTemplateFieldInstances ?? EMPTY_ARRAY).map(
            getFieldInstanceFieldsFromAcyclicFieldInstanceFields,
          );
        }
        case "workflow": {
          const data = workflowResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflow;
          return data?.workflowFieldInstances ?? EMPTY_ARRAY;
        }
        default:
          assertNever(workflowContext);
      }
    }, [optionsConfig, workflowTemplateResult?.data, workflowResult?.data]);

    const fieldInstancesFromPrevious: FieldInstanceFields[] = useMemo(() => {
      if (optionsConfig.type === "hardcoded") {
        return EMPTY_ARRAY;
      }

      const { workflowContext } = optionsConfig;

      switch (workflowContext.type) {
        case "workflowTemplate": {
          const data = workflowTemplateResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate;
          return data?.actionItemTemplateFieldInstances ?? EMPTY_ARRAY;
        }
        case "workflow": {
          const data = workflowResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflow;
          return [data?.actionItemFieldInstances, data?.actionItemTemplateFieldInstances].filter(isDefined).flat();
        }
        default:
          assertNever(workflowContext);
      }
    }, [
      optionsConfig,
      workflowResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflow,
      workflowTemplateResult?.data?.fieldInstancesAvailableAsSourceValuesInWorkflowTemplate,
    ]);

    // Loading and selecting field options is enabled if the options config for fields is defined.
    // Add the extra check for the linked workflows FF since allowing fields to be selected is a
    // substantial change to this component.
    const isSelectingFieldsEnabled = fieldOptionsConfig != null;

    // If configuring custom fields is enabled, the user will be prompted to provide a value upon
    // selecting a field option.
    const isConfiguringFieldsEnabled = isSelectingFieldsEnabled && fieldOptionsConfig.configureFieldProps != null;
    const [configureCustomFieldsFormFieldsWithMetadata, setConfigureCustomFieldsFormFieldsWithMetadata] =
      useState<RegrelloConfigureCustomFieldsForm.FieldWithMetadata[]>(EMPTY_ARRAY);
    const [selectedField, setSelectedField] = useState<FieldFields | undefined>(undefined);
    const [isConfigureCustomFieldsFormOpen, setIsConfigureCustomFieldsFormOpen] = useState(false);

    const fields: FieldFields[] = useMemo(() => {
      return isSelectingFieldsEnabled
        ? (fieldOptionsConfig.filterOptions != null
            ? fieldsResult.data?.fields.filter(fieldOptionsConfig.filterOptions)
            : fieldsResult.data?.fields) ?? EMPTY_ARRAY
        : EMPTY_ARRAY;
    }, [fieldsResult.data?.fields, isSelectingFieldsEnabled, fieldOptionsConfig]);

    const spectrumFields: SpectrumFieldVersionFields[] = useMemo(() => {
      if (!isSelectingFieldsEnabled) {
        return EMPTY_ARRAY;
      }

      if (spectrumFieldsResult.data?.latestSpectrumFieldVersionsV2.fields == null) {
        return EMPTY_ARRAY;
      }

      return fieldOptionsConfig?.filterOptions == null
        ? spectrumFieldsResult.data?.latestSpectrumFieldVersionsV2.fields
        : spectrumFieldsResult.data?.latestSpectrumFieldVersionsV2.fields.filter(
            (option: SpectrumFieldVersionFields) => {
              const field = option.field;
              if (field == null) {
                return false;
              }

              // (akager) we know that fieldOptionsConfig.filterOptions is not null here
              // but need this check to satisfy the type checker.
              if (fieldOptionsConfig?.filterOptions == null) {
                return false;
              }

              return fieldOptionsConfig.filterOptions(field);
            },
          );
    }, [fieldOptionsConfig, isSelectingFieldsEnabled, spectrumFieldsResult.data?.latestSpectrumFieldVersionsV2.fields]);

    const fieldInstanceIdsFromParent: Set<number> = useMemo(() => {
      return new Set(fieldInstancesFromParent.map(getFieldInstanceId));
    }, [fieldInstancesFromParent]);

    const fieldInstanceIdsFromPrevious: Set<number> = useMemo(() => {
      return new Set(fieldInstancesFromPrevious.map(getFieldInstanceId));
    }, [fieldInstancesFromPrevious]);

    const isFieldInstanceOptionsLoading =
      optionsConfig.type === "asyncLoaded" &&
      ((optionsConfig.workflowContext.type === "workflow" && isQueryResultLoading(workflowResult)) ||
        (optionsConfig.workflowContext.type === "workflowTemplate" && isQueryResultLoading(workflowTemplateResult)));

    const isFieldInstanceOptionsLoadError =
      optionsConfig.type === "asyncLoaded" &&
      ((optionsConfig.workflowContext.type === "workflow" && isQueryResultError(workflowResult)) ||
        (optionsConfig.workflowContext.type === "workflowTemplate" && isQueryResultError(workflowTemplateResult)));

    const preFilterOptions = useCallback(
      (fieldInstance: FieldInstanceFields) => {
        return (
          (!isInactiveFieldInstancesHidden || fieldInstance.field.deletedAt == null) &&
          allowedFieldPlugins != null &&
          allowedFieldPlugins.length > 0 &&
          allowedFieldPlugins.some((plugin) => plugin.canProcessField(fieldInstance.field)) &&
          (filterCondition?.(fieldInstance) ?? true)
        );
      },
      [allowedFieldPlugins, filterCondition, isInactiveFieldInstancesHidden],
    );

    // (hchen): Build a set for faster lookup, given that we'll need to filter the options
    // every time the suggestions menu opens.
    const omittedOptionIds = useMemo(
      () => new Set((omittedOptions ?? EMPTY_ARRAY).map((option) => getFieldInstanceId(option))),
      [omittedOptions],
    );

    // (zstanik): Don't show field options that already exist on the workflow or blueprint since
    // that's where fields will be added if these options are selected.
    const omittedFieldIds = useMemo(() => {
      return new Set(fieldInstancesFromParent.map((fieldInstance) => fieldInstance.field.id));
    }, [fieldInstancesFromParent]);

    // Update and sort the locally stored fields when the query finishes loading.
    useEffect(() => {
      const fieldInstancesFromPreviousWithSources = hydrateSourcesInFieldInstances(
        fieldInstancesFromPrevious,
        fieldInstancesFromPrevious,
      );

      // Need to pre-group the options by workflow/template and previous tasks first, otherwise
      // the real-time select input grouping creates multiple split-up groups.
      setLoadedOptions(
        [
          ...sortOptionsForFieldInstancesWithSources(fieldInstancesFromParent.filter(preFilterOptions)),
          ...sortOptionsForFieldInstancesWithSources(fieldInstancesFromPreviousWithSources.filter(preFilterOptions)),
          ...sortOptionsForFieldInstancesWithSources(
            isSpectrumEnabled
              ? spectrumFields
                  .filter(
                    (spectrumField) => spectrumField.field != null && !omittedFieldIds.has(spectrumField.field.id),
                  )
                  .map(wrapSpectrumFieldWithMockFieldInstance)
                  .filter(preFilterOptions)
              : fields
                  .filter((field) => !omittedFieldIds.has(field.id))
                  .map(wrapFieldWithMockFieldInstance)
                  .filter(preFilterOptions),
          ),
        ].filter((fieldInstance) => !omittedOptionIds.has(getFieldInstanceId(fieldInstance))),
      );
    }, [
      fieldInstancesFromParent,
      fieldInstancesFromPrevious,
      fields,
      isSpectrumEnabled,
      omittedFieldIds,
      omittedOptionIds,
      preFilterOptions,
      spectrumFields,
    ]);

    const handleAutocompleteOpen = useCallback(() => {
      if (optionsConfig.type === "asyncLoaded") {
        const { dependingOnActionItemTemplateId, workflowContext } = optionsConfig;

        // Query for field instances available in the workflow context, when the menu opens.
        switch (workflowContext.type) {
          case "workflowTemplate":
            void getFieldInstancesAvailableAsSourceValuesInWorkflowTemplateAsync({
              variables: {
                workflowTemplateId: workflowContext.workflowTemplateId,
                currentWorkflowTemplateStageId: workflowContext.workflowStageTemplateId,
                dependingOnActionItemTemplateId: dependingOnActionItemTemplateId,
                dependingOnWorkflowTemplateStageId: workflowContext.dependingOnWorkflowStageTemplateId,
              },
            });
            break;
          case "workflow":
            void getFieldInstancesAvailableAsSourceValuesInWorkflowAsync({
              variables: {
                workflowId: workflowContext.workflowId,
                currentWorkflowStageId: workflowContext.workflowStageId,
                dependingOnActionItemTemplateId: dependingOnActionItemTemplateId,
                dependingOnWorkflowStageId: workflowContext.dependingOnWorkflowStageId,
              },
            });
            break;
          default:
            assertNever(workflowContext);
        }
      }
      if (isSelectingFieldsEnabled) {
        // Fetch the fields if selecting fields is enabled and configured to be "asyncLoaded".
        if (isSpectrumEnabled) {
          void getSpectrumFieldsAsync();
        } else {
          void getFieldsAsync();
        }
      }
    }, [
      getFieldInstancesAvailableAsSourceValuesInWorkflowAsync,
      getFieldInstancesAvailableAsSourceValuesInWorkflowTemplateAsync,
      getFieldsAsync,
      getSpectrumFieldsAsync,
      isSelectingFieldsEnabled,
      isSpectrumEnabled,
      optionsConfig,
    ]);

    const handleChange = useCallback(
      (nextValue: FieldInstanceFields | null, reason: RegrelloSelectChangeReason) => {
        if (nextValue != null) {
          const fieldInstanceId = getFieldInstanceId(nextValue);
          if (isSelectingFieldsEnabled && fieldInstanceId === FIELD_OPTION_ID) {
            if (isConfiguringFieldsEnabled) {
              setSelectedField(nextValue.field);
              setConfigureCustomFieldsFormFieldsWithMetadata([
                {
                  field: nextValue.field,
                  spectrumField: nextValue.spectrumFieldVersion ?? undefined,
                  isMultiValued: fieldOptionsConfig.configureFieldProps?.isMultiValued,
                  projection: fieldOptionsConfig.configureFieldProps?.projection,
                },
              ]);
              setIsConfigureCustomFieldsFormOpen(true);
            } else {
              // Temporarily set the selected option until the field instance is created and
              // replaces the selected value. Otherwise, an error state will briefly flash while the
              // field instance is being created.
              onChange(nextValue, reason);
              fieldOptionsConfig.onFieldOptionSelected(nextValue.field, undefined);
            }
            return;
          }
        }

        onChange(nextValue, reason);
      },
      [onChange, isSelectingFieldsEnabled, isConfiguringFieldsEnabled, fieldOptionsConfig],
    );

    const handleCreateFieldDialogClose = useCallback(() => setIsCreateFieldDialogOpen(false), []);

    const handleFieldCreated = useCallback(
      (newField: FieldFields) => {
        if (isConfiguringFieldsEnabled) {
          setSelectedField(newField);
          setConfigureCustomFieldsFormFieldsWithMetadata([
            {
              field: newField,
              isMultiValued: fieldOptionsConfig.configureFieldProps?.isMultiValued,
              projection: fieldOptionsConfig.configureFieldProps?.projection,
            },
          ]);
          setIsConfigureCustomFieldsFormOpen(true);
        } else {
          // Temporarily set the selected option until the field instance is created and replaces
          // the selected value. Otherwise, an error state will briefly flash while the field
          // instance is being created.
          onChange(wrapFieldWithMockFieldInstance(newField), "create-option");
          fieldOptionsConfig?.onFieldOptionSelected(newField, undefined);
        }
      },
      [fieldOptionsConfig, isConfiguringFieldsEnabled, onChange],
    );

    const handleConfigureCustomFieldFormSubmit = useCallback(
      async (data: RegrelloConfigureCustomFieldsForm.Fields) => {
        if (isConfiguringFieldsEnabled && data.customFields.length > 0) {
          const { field, spectrumField, values } = data.customFields[0];
          const currentField = getSpectrumFieldOrLegacyFieldViaFF(field, spectrumField);
          if (currentField != null) {
            // Temporarily set the selected option until the field instance is created and replaces
            // the selected value. Otherwise, an error state will briefly flash while the field
            // instance is being created.
            onChange(wrapFieldWithMockFieldInstance(currentField), "select-option");
            fieldOptionsConfig.onFieldOptionSelected(currentField, values);
            return true;
          }
        }
        return false;
      },
      [fieldOptionsConfig, isConfiguringFieldsEnabled, onChange],
    );

    const { isUploading, onUploadFinish, onUploadStart } = useDocumentUploadStatus({
      documentKeys:
        selectedField != null && DocumentFieldPlugin.canProcessField(selectedField) ? [selectedField.id] : EMPTY_ARRAY,
    });

    const handleConfigureCustomFieldsFormClose = useCallback(() => {
      setIsConfigureCustomFieldsFormOpen(false);
    }, []);

    const renderOption = useCallback(
      (option: FieldInstanceFields | null) => {
        if (option == null) {
          return null;
        }

        const fieldInstanceId = getFieldInstanceId(option);

        // (clewis): Must access name after the options above, because .field.name doesn't exist on
        // special options.
        const fieldInstanceName = option.field.name;

        const detailSnippets =
          fieldInstanceIdsFromPrevious.has(fieldInstanceId) && isSourceHelperTextVisible
            ? parse(
                FromPreviousHelperText(getCustomFieldSourceName(getCustomFieldSource(option))),
                match(fieldInstanceName, EMPTY_STRING),
              )
            : undefined;

        return (
          <RegrelloFormFieldSelectOption
            detailSnippets={detailSnippets}
            isSelected={false}
            mainSnippets={[{ text: fieldInstanceName, highlight: false }]}
            startAdornment={
              <div className="mr-1">
                {isSpectrumEnabled && option.spectrumFieldVersion ? (
                  <RegrelloIcon
                    iconName={SpectrumFieldPluginRegistrar.getPluginForSpectrumField(
                      option.spectrumFieldVersion,
                    )?.getIconName(
                      option.spectrumFieldVersion.field?.fieldType,
                      option.spectrumFieldVersion.field ?? undefined,
                    )}
                  />
                ) : (
                  CustomFieldPluginRegistrar.getPluginForField(option.field).renderIcon({
                    fieldType: option.field.fieldType,
                    field: option.field,
                  })
                )}
              </div>
            }
          />
        );
      },
      [fieldInstanceIdsFromPrevious, isSourceHelperTextVisible, isSpectrumEnabled],
    );

    const renderSelectedValue = useCallback(
      (option: FieldInstanceFields | null) => {
        if (option == null) {
          return null;
        }

        const fieldInstanceId = getFieldInstanceId(option);

        // (clewis): Must access name after the options above, because .field.name doesn't exist on
        // special options.
        const fieldInstanceName = option.field.name;

        return (
          <RegrelloTooltipV4
            align="start"
            content={fieldInstanceId !== FIELD_OPTION_ID ? getOptionTooltip?.(option) : undefined}
          >
            <div>
              <RegrelloFormFieldSelectOption
                isSelected={false}
                mainSnippets={[{ text: fieldInstanceName, highlight: false }]}
                startAdornment={
                  <div className="mr-1">
                    {isSpectrumEnabled && option.spectrumFieldVersion ? (
                      <RegrelloIcon
                        iconName={SpectrumFieldPluginRegistrar.getPluginForSpectrumField(
                          option.spectrumFieldVersion,
                        )?.getIconName(
                          option.spectrumFieldVersion.field?.fieldType,
                          option.spectrumFieldVersion.field ?? undefined,
                        )}
                      />
                    ) : (
                      CustomFieldPluginRegistrar.getPluginForField(option.field).renderIcon({
                        fieldType: option.field.fieldType,
                        field: option.field,
                      })
                    )}
                  </div>
                }
              />
            </div>
          </RegrelloTooltipV4>
        );
      },
      [getOptionTooltip, isSpectrumEnabled],
    );

    const renderGroupHeading: (groupName: string) => React.ReactNode = useCallback(
      (groupName) => {
        const labelIfFieldGroup =
          fieldOptionsConfig?.workflowOrBlueprintName != null
            ? AddFieldToParent(fieldOptionsConfig.workflowOrBlueprintName)
            : optionsConfig.type === "asyncLoaded" && optionsConfig.workflowContext.type === "workflow"
              ? AddFieldToWorkflow
              : AddFieldToBlueprint;
        const labelIfFieldInstanceGroup = groupName;
        return (
          <RegrelloTypography muted={true}>
            {groupName === FIELD_OPTION_GROUP_NAME ? labelIfFieldGroup : labelIfFieldInstanceGroup}
          </RegrelloTypography>
        );
      },
      [fieldOptionsConfig?.workflowOrBlueprintName, optionsConfig],
    );

    const groupBy = useCallback(
      (option: FieldInstanceFieldsWithSource) => {
        const fieldInstanceId = getFieldInstanceId(option);
        if (isSelectingFieldsEnabled && fieldInstanceId === FIELD_OPTION_ID) {
          return FIELD_OPTION_GROUP_NAME;
        }
        if (fieldInstanceIdsFromParent.has(fieldInstanceId)) {
          return FromWorkflow;
        }

        if (option.source?.type === "previous") {
          const stageName = option.source.stageName ?? option.source.stageTemplateName;
          return `${Stage} ${stageName}`;
        }

        if (fieldInstanceIdsFromPrevious.has(fieldInstanceId)) {
          return FromPreviousTasks;
        }
        // For the 'loading' and 'error' options:
        return EMPTY_STRING;
      },
      [fieldInstanceIdsFromParent, fieldInstanceIdsFromPrevious, isSelectingFieldsEnabled],
    );

    const getOptionDisabledInternal = useCallback(
      (option: FieldInstanceFields) => {
        const fieldInstanceId = getFieldInstanceId(option);
        const shouldDisableOptionalFieldInstance =
          !isAllowEmptyOptionalFieldInstances &&
          getCustomFieldInstanceInputType(option) === FieldInstanceValueInputType.OPTIONAL &&
          (option.workflow?.scheduleStatus != null || option.actionItem?.status === ActionItemStatus.COMPLETED) &&
          CustomFieldPluginRegistrar.getPluginForFieldInstance(option).isFieldInstanceEmpty(option);

        return (
          // For now, don't let field options be disabled. They aren't fully field instance options
          // so we need to be careful about passing them into callback props that expect true field
          // instances.
          fieldInstanceId !== FIELD_OPTION_ID && (getOptionDisabled?.(option) || shouldDisableOptionalFieldInstance)
        );
      },
      [getOptionDisabled, isAllowEmptyOptionalFieldInstances],
    );

    const getOptionTooltipInternal = useCallback(
      (option: FieldInstanceFields) => {
        const fieldInstanceId = getFieldInstanceId(option);
        return fieldInstanceId !== FIELD_OPTION_ID ? getOptionTooltip?.(option) : undefined;
      },
      [getOptionTooltip],
    );

    return (
      <>
        <RegrelloFormFieldSelectV2
          className={className}
          extraEndOptions={[
            isFieldInstanceOptionsLoadError ? (
              <RegrelloSelectV2ErrorOption key="option-error" />
            ) : fieldOptionsConfig?.isCreatingFieldsAllowed ? (
              <RegrelloSelectV2AddOption
                key="option-add"
                allowCreateOptions={true}
                iconName="add"
                onSelect={(inputValue) => {
                  setDefaultValueForCreateFieldDialog(inputValue);
                  setIsCreateFieldDialogOpen(true);
                }}
              />
            ) : null,
          ]}
          getOptionDisabled={getOptionDisabledInternal}
          getOptionLabel={getFieldLabel}
          getOptionTooltip={getOptionTooltipInternal}
          getOptionValue={(option) => getFieldLabel(option) + String(getFieldInstanceId(option))}
          groupBy={groupBy}
          isClearButtonEnabled={selectProps.onClearClick != null}
          isLoading={isFieldInstanceOptionsLoading}
          isTooltipEnabled={true}
          onChange={handleChange}
          onOpen={handleAutocompleteOpen}
          options={
            optionsConfig.type === "hardcoded"
              ? isSelectingFieldsEnabled
                ? [...optionsConfig.options, ...fields.map(wrapFieldWithMockFieldInstance)]
                : optionsConfig.options
              : filteredLoadedOptions
          }
          renderGroupHeading={renderGroupHeading}
          renderOption={renderOption}
          renderSelectedValue={renderSelectedValue}
          {...selectProps}
        />

        <CreateFieldDialog
          allowedFieldPlugins={allowedFieldPlugins}
          allowMultipleSwitchDisabledHelperText={fieldOptionsConfig?.allowMultipleSwitchDisabledHelperText}
          defaultAllowMultiple={fieldOptionsConfig?.defaultAllowMultiple}
          defaultValue={defaultValueForCreateFieldDialog}
          isOpen={isCreateFieldDialogOpen}
          onClose={handleCreateFieldDialogClose}
          onFieldCreated={handleFieldCreated}
          submitButtonText={isConfiguringFieldsEnabled ? DialogActionNext : Add}
        />

        <RegrelloFormDialog<RegrelloConfigureCustomFieldsForm.Fields>
          dataTestId={DataTestIds.PROVIDE_MISSING_INPUT_FIELD_FORM_DIALOG}
          defaultValues={RegrelloConfigureCustomFieldsForm.getDefaultValuesFromFieldsAndMetadata(
            configureCustomFieldsFormFieldsWithMetadata,
          )}
          description={fieldOptionsConfig?.configureFieldProps?.description ?? ConfigureFieldHelperText}
          isOpen={isConfigureCustomFieldsFormOpen}
          isSubmitDisabled={isUploading}
          onClose={handleConfigureCustomFieldsFormClose}
          onSubmit={handleConfigureCustomFieldFormSubmit}
          scopeNameForSentry={RegrelloFormDialogName.CONFIGURE_CUSTOM_FIELDS}
          submitButtonText={Add}
          title={fieldOptionsConfig?.configureFieldProps?.title ?? CreateNewFieldEmpty}
        >
          {(form) => (
            <RegrelloConfigureCustomFieldsForm.Component
              allowCreateUsers={fieldOptionsConfig?.configureFieldProps?.isCreatingUsersAllowed}
              disallowDeleteFields={true}
              disallowSelectInputType={true}
              form={form}
              initialFieldsWithMetadata={configureCustomFieldsFormFieldsWithMetadata}
              isAddFormButtonHidden={true}
              isOnlyEditingValues={true}
              isStandaloneFormSection={true}
              onValueChangeFinish={onUploadFinish}
              onValueChangeStart={onUploadStart}
              spectrumConfigurationFormProps={{
                spectrumFormManager: configureSpectrumFormManager,
                disallowAddForms: true,
              }}
            />
          )}
        </RegrelloFormDialog>
      </>
    );
  },
);

function getFieldLabel(option: FieldInstanceFields): string {
  return option.field.name;
}

function sortOptionsForFieldInstancesWithSources(
  options: FieldInstanceFieldsWithSource[],
): FieldInstanceFieldsWithSource[] {
  const fieldInstancesWithSourcesComparator = getFieldInstancesWithSourcesComparator({ groupByStage: true });
  return [...options.sort(fieldInstancesWithSourcesComparator)];
}

function wrapFieldWithMockFieldInstance(field: FieldFields): FieldInstanceFields {
  return createSpecialOptionWithIdAndMaybeField(FIELD_OPTION_ID, field);
}

function wrapSpectrumFieldWithMockFieldInstance(spectrumField: SpectrumFieldVersionFields): FieldInstanceFields {
  return {
    field: spectrumField.field ?? { name: "" },
    values: [{ id: FIELD_OPTION_ID }],
    spectrumFieldVersion: spectrumField,
  } as unknown as FieldInstanceFields;
}
