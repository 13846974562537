import { CalendarDate, getLocalTimeZone, GregorianCalendar } from "@internationalized/date";
import { useDateField } from "@react-aria/datepicker";
import { DateFieldStateOptions, useDateFieldState } from "@react-stately/datepicker";
import { clsx, WithClassName } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import React, { lazy, Suspense, useCallback, useMemo, useRef, useState } from "react";

import { RegrelloDateSegment } from "./RegrelloDateSegment";
import { RegrelloIntentV2 } from "../../utils/enums/RegrelloIntentV2";
import { RegrelloButton } from "../button/RegrelloButton";
import { buttonVariants } from "../button/regrelloButtonUtils";
import { RegrelloIcon } from "../icons/RegrelloIcon";
import { getInputV2ButtonSizeForInputSize, INPUT_V2_DEFAULT_SIZE } from "../input/inputV2Utils";
import { RegrelloInput, RegrelloInputProps } from "../input/RegrelloInput";
import { RegrelloPopoverContent, RegrelloPopoverRoot, RegrelloPopoverTrigger } from "../popover/RegrelloPopover";
import { RegrelloSpinner } from "../spinner/RegrelloSpinner";

const RegrelloCalendar = lazy(async () => {
  const component = await import("./RegrelloCalendar");
  return { default: component.RegrelloCalendar };
});

export interface RegrelloDatePickerProps
  extends Pick<DateFieldStateOptions, "maxGranularity">,
    Pick<RegrelloInputProps, "fullWidth" | "size" | "disabled" | "inputRef">,
    WithClassName {
  /** Default value for uncontrolled input */
  defaultValue?: Date;

  /**
   * Ref to the leftmost date segment. Allows the input to be programmatically focused if the input
   * is missing a value when a form is submitted.
   */
  firstSegmentRef?: React.Ref<HTMLElement>;

  /** Apply the disabled modifier to the matching days. */
  getIsDateDisabled?: (date: Date) => boolean;

  /**
   * Wheather to hide `clear date` button.
   * @default false
   */
  hideClearButton?: boolean;

  /**
   * The semantic intent of this input.
   * @default RegrelloIntentV2.NEUTRAL
   */
  intent?: Extract<RegrelloIntentV2, "neutral" | "danger" | "warning">;

  /** Accessible name for the input */
  label?: string;

  /** Maximum selectable date */
  maxValue?: Date;

  /** Minimum selectable date */
  minValue?: Date;

  /** Change handler for the controlled value. Must be set along `value` property. */
  onChange?: (date: Date | string | null) => void;

  /** Currently selected controlled value. Must be set along `onChange` property. */
  value?: Date | string | null;

  /**
   * Should chosen value be represented as string instead of `Date`
   */
  valueAsString?: boolean;
}

export type RegrelloDatePickerImperativeHandle = {
  /** Focuses the first segment in the date input. */
  focus: () => void;
};

/**
 * This component provides tokenized date input with the calendar popover.
 */
export function RegrelloDatePicker({
  className,
  defaultValue,
  disabled,
  firstSegmentRef,
  fullWidth,
  getIsDateDisabled,
  hideClearButton = false,
  inputRef,
  intent = "neutral",
  label = "date picker",
  minValue,
  maxGranularity,
  maxValue,
  onChange,
  size = INPUT_V2_DEFAULT_SIZE,
  value,
  valueAsString,
  ...props
}: RegrelloDatePickerProps) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [internalDate, setInternalDate] = useState<CalendarDate | null>(null);

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prev) => !prev);
  }, []);

  const controlledValue = useMemo(() => {
    if (value != null) {
      return toCalendarDate(isDate(value) ? value : new Date(value));
    }
    return internalDate;
  }, [internalDate, value]);

  const controlledOnChange = useCallback(
    (date: CalendarDate | null) => {
      if (date != null) {
        const nativeDate = date.toDate(getLocalTimeZone());

        // (dosipiuk): Fix preventing native JS to forcefull set min year of 1900 - year bump is
        // handled in year token blur event.
        nativeDate?.setFullYear(date.year);

        if (valueAsString) {
          onChange?.(nativeDate.toISOString());
        } else {
          onChange?.(nativeDate);
        }
      } else {
        onChange?.(null);
      }

      setInternalDate(date);
    },
    [onChange, valueAsString],
  );

  const onDateChange = useCallback(
    (date?: Date) => {
      if (onChange != null) {
        if (valueAsString) {
          onChange(date?.toISOString() || null);
        } else {
          onChange(date || null);
        }
      }
      setInternalDate(toCalendarDate(date) || null);
    },
    [onChange, valueAsString],
  );

  const controlledDefaultValue = useMemo(() => toCalendarDate(defaultValue), [defaultValue]);
  const controlledMinValue = useMemo(() => toCalendarDate(minValue) || undefined, [minValue]);
  const controlledMaxValue = useMemo(() => toCalendarDate(maxValue) || undefined, [maxValue]);

  const state = useDateFieldState({
    createCalendar,
    defaultValue: controlledDefaultValue,
    isDisabled: disabled,
    // By default use user locale, then fall back to en-US
    locale: navigator.languages?.[0] || "en-US",
    value: controlledValue,
    onChange: controlledOnChange,
    minValue: controlledMinValue,
    maxGranularity: maxGranularity,
    maxValue: controlledMaxValue,
  });

  const refInternal = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { fieldProps } = useDateField(
    {
      ...props,
      isDisabled: disabled,
      "aria-label": label,
    },
    state,
    refInternal,
  );

  /** Converts dates in the short format to current millenia. Ex. 22 => 2022. */
  const onYearBlur = useCallback(() => {
    if (internalDate != null && internalDate.year < 1000) {
      const currentMillenia = Math.floor(new Date().getFullYear() / 1000) * 1000;

      const newDate = internalDate?.toDate(getLocalTimeZone());
      newDate.setFullYear(newDate.getFullYear() + currentMillenia);

      onDateChange(newDate);
    }
  }, [internalDate, onDateChange]);

  const calendarSelectedDate = useMemo(() => {
    if (internalDate) {
      return internalDate.toDate(getLocalTimeZone());
    }
    return undefined;
  }, [internalDate]);

  return (
    <RegrelloPopoverRoot
      onOpenChange={(nextOpenState) => {
        if (!nextOpenState) {
          buttonRef.current?.focus();
        }
      }}
      open={isPopoverOpen}
    >
      <RegrelloInput
        ref={inputRef}
        asChild={true}
        className={clsx(className, "pr-0", {
          "pl-1": size === "small" || size === "medium",
          "pl-1.5": size === "large",
          "pl-2": size === "x-large",
        })}
        disabled={disabled}
        endElement={{
          type: "interactive",
          element: (
            <div className="flex gap-1">
              {hideClearButton ? null : (
                <RegrelloButton
                  aria-label="clear date, date picker"
                  className={clsx({ invisible: controlledValue == null })}
                  iconOnly={true}
                  onClick={() => {
                    onDateChange();
                    buttonRef.current?.focus();
                  }}
                  size={getInputV2ButtonSizeForInputSize(size)}
                  startIcon="close"
                  variant="ghost"
                />
              )}
              <RegrelloPopoverTrigger asChild={true} onClick={togglePopover}>
                {size === "x-small" ? (
                  // (clewis): Use custom, super-tiny button to fit in the borders of the input.
                  <button
                    ref={buttonRef}
                    aria-label="open calendar, date picker"
                    className={clsx(buttonVariants({ variant: "ghost" }), "mt-1 px-px")}
                    data-testid={DataTestIds.FORM_FIELD_DATE_CALENDAR_BUTTON}
                    disabled={disabled}
                    type="button"
                  >
                    <RegrelloIcon iconName="date-field" intent="neutral" size="x-small" />
                  </button>
                ) : size === "small" ? (
                  <button
                    ref={buttonRef}
                    aria-label="open calendar, date picker"
                    className={clsx(buttonVariants({ variant: "ghost" }), "mt-0.75 p-0.5")}
                    data-testid={DataTestIds.FORM_FIELD_DATE_CALENDAR_BUTTON}
                    disabled={disabled}
                    type="button"
                  >
                    <RegrelloIcon iconName="date-field" intent="neutral" size="x-small" />
                  </button>
                ) : (
                  <RegrelloButton
                    ref={buttonRef}
                    aria-label="open calendar, date picker"
                    dataTestId={DataTestIds.FORM_FIELD_DATE_CALENDAR_BUTTON}
                    disabled={disabled}
                    iconOnly={true}
                    size={getInputV2ButtonSizeForInputSize(size)}
                    startIcon="date-field"
                    variant="ghost"
                  />
                )}
              </RegrelloPopoverTrigger>
            </div>
          ),
        }}
        fullWidth={fullWidth}
        intent={intent}
        size={size}
        {...props}
      >
        <div ref={refInternal} className="flex items-center" {...fieldProps}>
          {state.segments.map((segment, i) => (
            <RegrelloDateSegment
              key={i}
              ref={i === 0 ? firstSegmentRef : undefined}
              intent={intent}
              onBlur={onYearBlur}
              segment={segment}
              size={size}
              state={state}
            />
          ))}
        </div>
      </RegrelloInput>

      <RegrelloPopoverContent
        align="end"
        className="w-auto min-w-78 min-h-70"
        omitDefaultPadding={true}
        onPointerDownOutside={togglePopover}
      >
        <Suspense fallback={<RegrelloSpinner />}>
          <RegrelloCalendar
            defaultMonth={calendarSelectedDate}
            disabled={getIsDateDisabled}
            fromDate={minValue}
            initialFocus={true}
            mode="single"
            onSelect={(date) => {
              onDateChange(date);
              buttonRef.current?.click();
            }}
            selected={calendarSelectedDate}
            toDate={maxValue}
          />
        </Suspense>
      </RegrelloPopoverContent>
    </RegrelloPopoverRoot>
  );
}

// (dosipiuk): This is here to treeshake alternative calendar implementations.
function createCalendar(identifier: string) {
  switch (identifier) {
    case "gregory":
      return new GregorianCalendar();
    default:
      throw new Error(`Unsupported calendar ${identifier}`);
  }
}

/**
 * Converts native JS Date to custom `react-aria` implementation.
 */
function toCalendarDate(date?: Date | null) {
  if (date == null) {
    return null;
  }

  return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

function isDate(date: string | Date): date is Date {
  return Object.prototype.toString.call(date) === "[object Date]";
}
