import { clsx, EMPTY_ARRAY } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionalExpressionGroupFields,
  ConditionConnective,
  FieldInstanceConditionalExpressionFields,
  FieldInstanceFields,
  Maybe,
} from "@regrello/graphql-api";
import { ScheduleTimeDaysToCompletePlaceholder, SelectField } from "@regrello/ui-strings";
import isArray from "lodash/isArray";
import React, { useCallback, useMemo, useRef } from "react";
import { useFieldArray, UseFormReturn, useWatch } from "react-hook-form";
import { useMount } from "react-use";

import { RegrelloConfigureConditionsFormSection } from "./RegrelloConfigureConditionsFormSection";
import { SCHEDULE_TIME_FORM_LABEL_WIDTH } from "./scheduleTimeConstants";
import { TimeToCompleteUnits, TimeUnit, ValidationRules } from "../../../../../constants/globalConstants";
import { CheckboxFieldPlugin } from "../../../../molecules/customFields/plugins/CheckboxFieldPlugin";
import { CurrencyFieldPlugin } from "../../../../molecules/customFields/plugins/CurrencyFieldPlugin";
import { DateFieldPlugin } from "../../../../molecules/customFields/plugins/DateFieldPlugin";
import { MultiSelectFieldPlugin } from "../../../../molecules/customFields/plugins/MultiSelectFieldPlugin";
import { NumberFieldPlugin } from "../../../../molecules/customFields/plugins/NumberFieldPlugin";
import { CustomFieldPluginRegistrar } from "../../../../molecules/customFields/plugins/registry/customFieldPluginRegistrar";
import { RegrelloObjectFieldPlugin } from "../../../../molecules/customFields/plugins/RegrelloObjectFieldPlugin";
import { SelectFieldPlugin } from "../../../../molecules/customFields/plugins/SelectFieldPlugin";
import { TextFieldPlugin } from "../../../../molecules/customFields/plugins/TextFieldPlugin";
import { ConditionOperatorConfig } from "../../../../molecules/customFields/plugins/types/ConditionOperator";
import { UserFieldPlugin } from "../../../../molecules/customFields/plugins/UserFieldPlugin";
import { RegrelloFormFieldLayout } from "../../../../molecules/formFields/_internal/RegrelloFormFieldLayout";
import {
  RegrelloControlledFormFieldCustomFieldInstanceSelect,
  RegrelloControlledFormFieldDate,
  RegrelloControlledFormFieldMultiSelect,
  RegrelloControlledFormFieldNumber,
  RegrelloControlledFormFieldSelect,
} from "../../../../molecules/formFields/controlled/regrelloControlledFormFields";

export enum ScheduleTimeValues {
  ON_WORKFLOW_START = "onWorkflowStart",
  START_CONDITION_WORKFLOW = "startConditionWorkflow",
  DEFAULT_START = "defaultStart",
  AFTER_DEPENDENCY = "afterDependency",
  START_CONDITION_STAGE = "startConditionStage",
  ON_DATE = "onDate",
  DAYS_TO_COMPLETE = "daysToComplete",
  TIME_TO_COMPLETE = "timeToComplete",
  TIME_TO_COMPLETE_UNIT = "timeToCompleteUnit",
  FROM_CUSTOM_FIELD = "fromCustomField",
}

export enum ScheduleTimeKeys {
  SCHEDULE_TIME = "scheduleTime",
  DEPENDENCY = "dependency",
  DEPENDENCIES = "dependencies",
  DATE = "date",
  CONNECTIVE = "connective",
  START_CONDITION_DEPENDENCY = "startConditionDependency",
  CONDITIONS = "conditions",
  DAYS_TO_COMPLETE = "daysToComplete",
  TIME_TO_COMPLETE = "timeToComplete",
  TIME_TO_COMPLETE_UNIT = "timeToCompleteUnit",
  CONTROLLER_CUSTOM_FIELD = "controllerCustomField",
}

/** A utility type for properly typecasting conditions when reading from `react-hook-form` form. */
export type FieldInstanceCondition = {
  fieldInstance: FieldInstanceFields;
  fieldInstancePropertyId?: Maybe<number>;
  operator: ConditionOperatorConfig;
  value1: unknown;
  value2: unknown;
};

export type ScheduleTimeOption = {
  option: ScheduleTimeValues;
  label: string;
};

/** Represents an object (e.g., stage) that something can be scheduled to start after. */
export type RegrelloScheduleStartAfterDependency = {
  id: number;
  name: string;
  dueOn?: string | null;
  actionItems?: Array<{ id: number }>;
};

// (zstanik): Placeholder start condition values. Note: this shouldn't be declared as an array here
// because then values may be added to it as the user interacts with the form and introduce form
// state bugs when empty values need to be populated.
const EMPTY_FORM_START_CONDITION = { fieldInstance: null, operator: null, value1: null, value2: null };

export namespace RegrelloScheduleTimeFormSectionBase {
  export interface Fields {
    [ScheduleTimeKeys.SCHEDULE_TIME]: ScheduleTimeValues;
    [ScheduleTimeKeys.DEPENDENCY]: RegrelloScheduleStartAfterDependency | null;
    [ScheduleTimeKeys.DEPENDENCIES]: RegrelloScheduleStartAfterDependency[];
    [ScheduleTimeKeys.DATE]: Date | null;
    [ScheduleTimeKeys.START_CONDITION_DEPENDENCY]: RegrelloScheduleStartAfterDependency | null;
    [ScheduleTimeKeys.CONNECTIVE]: ConditionConnective;
    [ScheduleTimeKeys.CONDITIONS]: Array<{
      // (zstanik): TypeScript kept erroring out if the correct types are provided here, so declare
      // as `any` for now and typecast to the appropriate type when reading values? from the form.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fieldInstance: any;
      fieldInstancePropertyId?: Maybe<number>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      operator: any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value1: any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value2: any;
    }>;
    [ScheduleTimeKeys.DAYS_TO_COMPLETE]: string | null;
    [ScheduleTimeKeys.TIME_TO_COMPLETE]: string | null;
    [ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT]: TimeUnit;
    [ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD]: FieldInstanceFields | null;
  }

  export interface Props {
    /**
     * The default schedule time option to automatically select when the form is opened and no
     * initial values are provided.
     * @default ScheduleTimeValues.DEFAULT_START
     */
    defaultOption?: ScheduleTimeValues;

    /**
     * The form for configuring the schedule time of the stage or action item. Should have `mode`
     * set to 'all' for form validation to work properly.
     */
    form: UseFormReturn<Fields>;

    /**
     * Label to render for the schedule time form.
     */
    formLabel: string;

    /**
     * Width of the schedule time form label.
     * @default SCHEDULE_TIME_FORM_LABEL_WIDTH
     */
    formLabelWidth?: number;

    /**
     * Initial values used to populate the edit dialog. The form will reset to these values whenever
     * the user switches context and returns to the dialog.
     */
    initialValues?: {
      controllerField?: FieldInstanceFields;
      date?: string;
      daysToComplete?: string;
      timeToComplete?: string;
      timeToCompleteUnit?: TimeUnit;
      startAfterDependency?: RegrelloScheduleStartAfterDependency;
      startAfterDependencies?: RegrelloScheduleStartAfterDependency[];
      startingConditions?: ConditionalExpressionGroupFields;
      startOnWorkflowStart?: boolean;
    };

    /**
     * The default time for time-picker is 11:59PM. Set this to True
     * to use Start of Day instead, i.e. 00:00AM.
     * @default false
     */
    isDefaultTimeStartOfDay?: boolean;

    /**
     * Whether the entire schedule time section is disabled.
     * @default false
     */
    isDisabled?: boolean;

    /**
     * Whether the schedule time fields are optional. Doesn't apply to start condition fields, as
     * those must be required to avoid misconfigured start conditions.
     * @default false
     */
    isOptional?: boolean;

    /** The earliest due date that `ScheduleTimeValues.ON_DATE` form is allowed to set. */
    minDueDate?: Date;

    /**
     * The options to render for the base schedule time select input.
     */
    options: ScheduleTimeOption[];

    /**
     * Whether to show a time-picker with the date-picker.
     * @default false
     */
    showTimePicker: boolean;

    /**
     * The options to render for the user to select as a start after dependency.
     */
    startAfterDependencies?: RegrelloScheduleStartAfterDependency[];

    /**
     * Current workflow or workflow template context for the stage or task. Used to determine which
     * field instances to show users when selecting fields for due dates or start conditions.
     */
    workflowContext:
      | {
          type: "workflowTemplate";
          workflowTemplateId: number;
          workflowStageTemplateId?: number;
          dependingOnActionItemTemplateId?: number;
        }
      | {
          type: "workflow";
          workflowId: number;
          workflowStageId?: number;
          dependingOnActionItemTemplateId?: number;
        };

    /**
     * Whether the user is allowed to show or select multiple start after dependencies.
     */
    enableMultiDependencies: boolean;
  }

  export function getFormStartingConditions(
    conditions: FieldInstanceConditionalExpressionFields[],
  ): FieldInstanceCondition[] {
    return conditions.reduce((arr: FieldInstanceCondition[], condition) => {
      const fieldInstance = condition.left;
      const fieldInstancePropertyId = condition.leftFieldInstancePropertyID;
      const operator =
        CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.left)
          .getConditionOperators()
          .find((startConditionOperator) => startConditionOperator.operator === condition.operatorV2) ?? null;
      const value1 =
        operator != null && operator.numFields > 0 && condition.right.length > 0
          ? CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.right[0]).getValueForFrontend(
              condition.right[0],
            )
          : null;
      const value2 =
        operator != null && operator.numFields > 1 && condition.right.length > 1
          ? CustomFieldPluginRegistrar.getPluginForFieldInstance(condition.right[1]).getValueForFrontend(
              condition.right[1],
            )
          : null;
      // (zstanik): The operator is the only value here that could be null AND shouldn't be null in
      // a valid start condition. Silently warning to avoid crashing the app on invalid data.
      if (operator != null) {
        arr.push({
          fieldInstance,
          fieldInstancePropertyId,
          operator,
          value1,
          value2,
        });
      } else {
        console.warn("Invalid condition operator for field instance", {
          operator: condition.operatorV2,
          fieldInstance,
        });
      }
      return arr;
    }, []);
  }

  export function getDefaultValues(
    defaultOption: ScheduleTimeValues,
    values?: {
      controllerField?: Maybe<FieldInstanceFields>;
      date?: Maybe<string>;
      daysToComplete?: Maybe<string>;
      timeToComplete?: Maybe<string>;
      timeToCompleteUnit?: Maybe<TimeUnit>;
      startAfterDependency?: Maybe<RegrelloScheduleStartAfterDependency>;
      startAfterDependencies?: Maybe<RegrelloScheduleStartAfterDependency[]>;
      startingConditions?: Maybe<ConditionalExpressionGroupFields>;
      startOnWorkflowStart?: boolean;
    },
  ): Fields {
    // TODO Conditional Branching: Find more elegant way to keep the FE in sync with the BE after
    // deleting a field without having to refetch all workflows and templates in the cache.
    const conditionsWithoutDeleted =
      values?.startingConditions != null
        ? values?.startingConditions.conditions.filter((condition) => condition.left.field.deletedAt == null)
        : EMPTY_ARRAY;

    const hasStartAfterDependencies =
      (values?.startAfterDependencies != null && values.startAfterDependencies.length > 0) ||
      values?.startAfterDependency != null;

    // TODO (Surya): Refactor this ternary to be clearer.
    const scheduleTimeOption =
      conditionsWithoutDeleted.length > 0 && (values?.startOnWorkflowStart || hasStartAfterDependencies)
        ? values?.startOnWorkflowStart
          ? ScheduleTimeValues.START_CONDITION_WORKFLOW
          : ScheduleTimeValues.START_CONDITION_STAGE
        : hasStartAfterDependencies
          ? ScheduleTimeValues.AFTER_DEPENDENCY
          : values?.date != null
            ? ScheduleTimeValues.ON_DATE
            : // (surya): Time takes precedence over date.
              values?.timeToComplete != null && values?.timeToComplete !== ""
              ? ScheduleTimeValues.TIME_TO_COMPLETE
              : values?.daysToComplete != null && values?.daysToComplete !== ""
                ? ScheduleTimeValues.DAYS_TO_COMPLETE
                : values?.controllerField != null
                  ? ScheduleTimeValues.FROM_CUSTOM_FIELD
                  : values?.startOnWorkflowStart
                    ? ScheduleTimeValues.ON_WORKFLOW_START
                    : defaultOption;
    return {
      [ScheduleTimeKeys.SCHEDULE_TIME]: scheduleTimeOption,
      [ScheduleTimeKeys.START_CONDITION_DEPENDENCY]:
        scheduleTimeOption === ScheduleTimeValues.START_CONDITION_STAGE
          ? // (elle) In conditional branching M5, since conditions with stages are not supported yet, we can
            // assume there will be a single stage when there are conditions.
            values?.startAfterDependencies?.[0] ?? values?.startAfterDependency ?? null
          : null,
      [ScheduleTimeKeys.CONNECTIVE]:
        values?.startingConditions != null ? values?.startingConditions.connective : ConditionConnective.AND,
      [ScheduleTimeKeys.CONDITIONS]:
        conditionsWithoutDeleted.length > 0
          ? getFormStartingConditions(conditionsWithoutDeleted)
          : [EMPTY_FORM_START_CONDITION],
      [ScheduleTimeKeys.DEPENDENCY]:
        scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY ? values?.startAfterDependency ?? null : null,
      [ScheduleTimeKeys.DEPENDENCIES]:
        scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY
          ? values?.startAfterDependencies ?? EMPTY_ARRAY
          : EMPTY_ARRAY,
      [ScheduleTimeKeys.DATE]: values?.date != null ? new Date(values?.date) : null,
      [ScheduleTimeValues.DAYS_TO_COMPLETE]: values?.daysToComplete ?? null,
      [ScheduleTimeValues.TIME_TO_COMPLETE]: values?.timeToComplete ?? null,
      [ScheduleTimeValues.TIME_TO_COMPLETE_UNIT]:
        scheduleTimeOption === ScheduleTimeValues.TIME_TO_COMPLETE
          ? values?.timeToCompleteUnit || TimeUnit.DAYS
          : TimeUnit.DAYS,
      [ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD]: values?.controllerField ?? null,
    };
  }

  export const Component = React.memo<Props>(function ComponentFn({
    defaultOption = ScheduleTimeValues.DEFAULT_START,
    form,
    formLabel,
    formLabelWidth = SCHEDULE_TIME_FORM_LABEL_WIDTH,
    initialValues,
    isDefaultTimeStartOfDay = false,
    isDisabled = false,
    isOptional = false,
    minDueDate,
    options,
    showTimePicker = false,
    startAfterDependencies,
    workflowContext,
    enableMultiDependencies,
  }: Props) {
    const scheduleTimeOption = useWatch({ control: form.control, name: ScheduleTimeKeys.SCHEDULE_TIME });

    // Tracks the form field select for choosing a start-after task. Used for auto-opening when the
    // schedule time key changes to "AFTER_DEPENDENCY"
    const startAfterSelectRef = useRef<HTMLButtonElement>(null);

    const {
      fields: startConditionRows,
      append: appendStartCondition,
      remove: removeStartCondition,
      replace: replaceStartConditions,
      update: updateStartCondition,
    } = useFieldArray({
      control: form.control,
      name: ScheduleTimeKeys.CONDITIONS,
      shouldUnregister: true,
    });

    const pureOptions = useMemo(() => {
      return options.map((option) => {
        return option.option;
      });
    }, [options]);

    const optionsToLabelMap = useMemo(() => {
      const optionsMap = new Map<ScheduleTimeValues, string>();
      options.forEach((option) => {
        optionsMap.set(option.option, option.label);
      });
      return optionsMap;
    }, [options]);

    const handleAddStartConditionRow = useCallback(() => {
      appendStartCondition(
        {
          fieldInstance: null,
          operator: null,
          value1: null,
          value2: null,
        },
        {
          shouldFocus: false,
        },
      );
    }, [appendStartCondition]);

    // TODO Conditional Branching: Find more elegant way to keep the FE in sync with the BE after
    // deleting a field without having to refetch all workflows and templates in the cache.
    const initialConditionsWithoutDeleted = useMemo(() => {
      return (
        initialValues?.startingConditions?.conditions.filter((condition) => condition.left.field.deletedAt == null) ??
        EMPTY_ARRAY
      );
    }, [initialValues?.startingConditions?.conditions]);

    const handleStartConditionStageDependencyChange = useCallback(
      (_: unknown, newValue: RegrelloScheduleStartAfterDependency | null) => {
        // (zstanik): Clear stale validation rules on the previous start condition field array rows.
        form.unregister(ScheduleTimeKeys.CONDITIONS);
        const restoreStageStartingConditions =
          initialValues?.startAfterDependency?.id === newValue?.id && initialConditionsWithoutDeleted.length > 0;
        form.setValue(ScheduleTimeKeys.START_CONDITION_DEPENDENCY, newValue);
        replaceStartConditions(
          restoreStageStartingConditions
            ? getFormStartingConditions(initialConditionsWithoutDeleted)
            : [EMPTY_FORM_START_CONDITION],
        );
      },
      [form, initialConditionsWithoutDeleted, initialValues?.startAfterDependency?.id, replaceStartConditions],
    );

    // (zstanik): Need to clear stale validation rules on any form fields the user doesn't need to
    // fill out anymore after switching options.
    const handleScheduleTimeKeyChange = useCallback(
      (_: unknown, newValue: ScheduleTimeValues | null) => {
        if (newValue === ScheduleTimeValues.DEFAULT_START) {
          form.reset(getDefaultValues(defaultOption));
        }

        if (newValue === ScheduleTimeValues.ON_WORKFLOW_START) {
          form.reset(getDefaultValues(defaultOption, { startOnWorkflowStart: true }));
        }

        if (newValue === ScheduleTimeValues.AFTER_DEPENDENCY) {
          if (enableMultiDependencies) {
            const initialStages =
              initialValues?.startingConditions == null || initialConditionsWithoutDeleted.length === 0
                ? initialValues?.startAfterDependencies ?? EMPTY_ARRAY
                : EMPTY_ARRAY;

            form.setValue(ScheduleTimeKeys.DEPENDENCIES, initialStages);
          } else {
            form.setValue(
              ScheduleTimeKeys.DEPENDENCY,
              initialValues?.startingConditions == null || initialConditionsWithoutDeleted.length === 0
                ? initialValues?.startAfterDependency ?? null
                : null,
            );

            // (dosipiuk): To prevent issues with material-ui focus traps, this needs at least 2 macrotasks flush.
            // Therefore converting to a timeout until we migrate to radix dialog.
            setTimeout(() => {
              startAfterSelectRef?.current?.click();
            }, 100);
          }
        } else if (enableMultiDependencies) {
          form.unregister(ScheduleTimeKeys.DEPENDENCIES);
        } else {
          form.unregister(ScheduleTimeKeys.DEPENDENCY);
        }

        if (newValue === ScheduleTimeValues.ON_DATE) {
          form.setValue(ScheduleTimeKeys.DATE, initialValues?.date != null ? new Date(initialValues.date) : null);
        } else {
          form.unregister(ScheduleTimeKeys.DATE);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_STAGE) {
          // (zstanik): Clear stale validation rules if user switched from workflow start conditions
          // option.
          const restoreStageStartingConditions =
            !initialValues?.startOnWorkflowStart &&
            initialValues?.startAfterDependency != null &&
            initialConditionsWithoutDeleted.length > 0;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          form.setValue(
            ScheduleTimeKeys.START_CONDITION_DEPENDENCY,
            restoreStageStartingConditions
              ? // (elle) In conditional branching M5, since conditions with stages are not supported yet, we can
                // assume there will be a single stage when there are conditions.
                enableMultiDependencies
                ? initialValues?.startAfterDependencies?.[0] ?? initialValues.startAfterDependency ?? null
                : initialValues.startAfterDependency ?? null
              : null,
          );
          replaceStartConditions(
            restoreStageStartingConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : [EMPTY_FORM_START_CONDITION],
          );
        } else {
          form.unregister(ScheduleTimeKeys.START_CONDITION_DEPENDENCY);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_WORKFLOW) {
          // (zstanik): Clear stale validation rules if user switched from stage start conditions
          // option.
          const restoreWorkflowStartConditions =
            initialValues?.startOnWorkflowStart &&
            initialValues.startAfterDependency == null &&
            initialConditionsWithoutDeleted.length > 0;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          replaceStartConditions(
            restoreWorkflowStartConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : [EMPTY_FORM_START_CONDITION],
          );
        } else if (newValue !== ScheduleTimeValues.START_CONDITION_STAGE) {
          form.unregister(ScheduleTimeKeys.CONDITIONS);
        }

        if (newValue === ScheduleTimeValues.START_CONDITION_WORKFLOW) {
          // (zstanik): Clear stale validation rules if user switched from stage start conditions
          // option.
          const restoreWorkflowStartConditions =
            initialValues?.startOnWorkflowStart &&
            initialValues.startAfterDependency == null &&
            initialConditionsWithoutDeleted.length > 0;
          form.unregister(ScheduleTimeKeys.CONDITIONS);
          replaceStartConditions(
            restoreWorkflowStartConditions
              ? getFormStartingConditions(initialConditionsWithoutDeleted)
              : [EMPTY_FORM_START_CONDITION],
          );
        } else if (newValue !== ScheduleTimeValues.START_CONDITION_STAGE) {
          form.unregister(ScheduleTimeKeys.CONDITIONS);
        }

        if (newValue === ScheduleTimeValues.DAYS_TO_COMPLETE) {
          form.setValue(ScheduleTimeKeys.DAYS_TO_COMPLETE, initialValues?.daysToComplete ?? null);
        } else {
          form.unregister(ScheduleTimeKeys.DAYS_TO_COMPLETE);
        }

        if (newValue === ScheduleTimeValues.TIME_TO_COMPLETE) {
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE, initialValues?.timeToComplete ?? null);
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT, initialValues?.timeToCompleteUnit ?? TimeUnit.DAYS);
        } else {
          form.unregister(ScheduleTimeKeys.TIME_TO_COMPLETE);
        }

        if (newValue === ScheduleTimeValues.TIME_TO_COMPLETE_UNIT) {
          form.setValue(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT, initialValues?.timeToCompleteUnit ?? TimeUnit.DAYS);
        } else if (newValue !== ScheduleTimeValues.TIME_TO_COMPLETE) {
          form.unregister(ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT);
        }

        if (newValue === ScheduleTimeValues.FROM_CUSTOM_FIELD) {
          form.setValue(ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD, initialValues?.controllerField ?? null);
        } else {
          form.unregister(ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD);
        }
      },
      [
        enableMultiDependencies,
        form,
        defaultOption,
        initialValues?.startingConditions,
        initialValues?.startAfterDependencies,
        initialValues?.startAfterDependency,
        initialValues?.date,
        initialValues?.startOnWorkflowStart,
        initialValues?.daysToComplete,
        initialValues?.timeToComplete,
        initialValues?.timeToCompleteUnit,
        initialValues?.controllerField,
        initialConditionsWithoutDeleted,
        replaceStartConditions,
      ],
    );

    const handleStartConditionFieldInstanceChange = useCallback(
      (newFieldInstance: FieldInstanceFields | null, index: number) => {
        // (zstanik): If the user switched from a BETWEEN operator, need to unregister the 2nd form
        // field to clear any stale validation rules.
        form.unregister(`conditions.${index}.value2`);

        // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
        // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
        // the fieldArray fields and set it as `never`.
        updateStartCondition(index, { fieldInstance: newFieldInstance, operator: null, value1: null, value2: null });
      },
      [form, updateStartCondition],
    );

    const handleStartConditionFieldPropertyChange = useCallback(
      (newFieldInstance: FieldInstanceFields | null, index: number, newPropertyId: number) => {
        // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
        // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
        // the fieldArray fields and set it as `never`.
        // @ts-expect-error TS expects all parameters to be provided
        updateStartCondition(index, { fieldInstance: newFieldInstance, fieldInstancePropertyId: newPropertyId });
      },
      [updateStartCondition],
    );

    const handleStartConditionOperatorChange = useCallback(
      (
        currentFieldInstance: FieldInstanceFields | null,
        fieldInstancePropertyId: number | null,
        newOperator: ConditionOperatorConfig | null,
        oldValue1: unknown,
        index: number,
      ) => {
        // (dyury): If the user switched from an operator with operands to an operator without operands  (e.g. IS_GREATER_THAN -> IS_EMPTY)
        // we need to clear validation rules
        form.unregister(`conditions.${index}.value1`);
        // (zstanik): If the user switched from a BETWEEN operator, need to unregister the 2nd form
        // field to clear any stale validation rules.
        form.unregister(`conditions.${index}.value2`);

        const newValue1 =
          currentFieldInstance != null && newOperator != null
            ? CustomFieldPluginRegistrar.getPluginForFieldInstance(currentFieldInstance).getEmptyValueForFrontend({
                operator: newOperator.operator,
              })
            : null;

        // (zstanik): Using `form.setValue()` would be ideal here since that doesn't remount the
        // inputs whereas update() does, but for some reason setValue couldn't recognize the type of
        // the fieldArray fields and set it as `never`, making it unusable.
        updateStartCondition(index, {
          fieldInstance: currentFieldInstance,
          fieldInstancePropertyId,
          operator: newOperator,
          value1: areOperatorValuesCompatible(oldValue1, newValue1) ? oldValue1 : newValue1,
          value2:
            currentFieldInstance != null && newOperator != null && newOperator.numFields === 2
              ? CustomFieldPluginRegistrar.getPluginForFieldInstance(currentFieldInstance).getEmptyValueForFrontend({
                  operator: newOperator.operator,
                })
              : null,
        });
      },
      [form, updateStartCondition],
    );

    useMount(() => {
      form.reset(
        getDefaultValues(defaultOption, {
          controllerField: initialValues?.controllerField,
          date: initialValues?.date,
          daysToComplete: initialValues?.daysToComplete,
          timeToComplete: initialValues?.timeToComplete,
          timeToCompleteUnit: initialValues?.timeToCompleteUnit,
          startAfterDependency: initialValues?.startAfterDependency,
          startAfterDependencies: initialValues?.startAfterDependencies,
          startingConditions: initialValues?.startingConditions,
          startOnWorkflowStart: initialValues?.startOnWorkflowStart,
        }),
      );
    });

    return (
      <>
        <RegrelloFormFieldLayout className="flex-col sm:flex-row mb-0" label={formLabel} labelWidth={formLabelWidth}>
          <div className="flex justify-start gap-2">
            <RegrelloControlledFormFieldSelect
              className={clsx(
                "min-w-[38%]", // (anthony): 38 is chosen intentionally to allow space for the new due date and time picker field.
                "grow-0", // (elle): Prevent the single select to grow and shrink into the right stage multi select when its content changes.
              )}
              controllerProps={{
                control: form.control,
                name: ScheduleTimeKeys.SCHEDULE_TIME,
                rules: ValidationRules.REQUIRED,
              }}
              dataTestId={DataTestIds.SCHEDULE_TIME_SELECT}
              disabled={isDisabled}
              getOptionDisabled={(option) =>
                option === ScheduleTimeValues.AFTER_DEPENDENCY && (startAfterDependencies?.length ?? 0) === 0
              }
              getOptionLabel={(option) => optionsToLabelMap.get(option) ?? ""}
              isFilterable={false}
              isRequiredAsteriskShown={true}
              onValueChange={handleScheduleTimeKeyChange}
              options={pureOptions}
            />
            {scheduleTimeOption === ScheduleTimeValues.AFTER_DEPENDENCY &&
              (enableMultiDependencies ? (
                <div className="grow min-w-0">
                  <RegrelloControlledFormFieldMultiSelect
                    className="min-h-15"
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.DEPENDENCIES,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_MULTI_SELECT}
                    disabled={isDisabled}
                    getSelectedItemProps={getSelectedItemProps}
                    isItemsEqual={(itemA, itemB) => itemA.id === itemB.id}
                    isRequiredAsteriskShown={!isOptional}
                    itemPredicate={itemPredicate}
                    items={startAfterDependencies ?? EMPTY_ARRAY}
                    renderItem={renderItem}
                  />
                </div>
              ) : (
                <div className="grow min-w-0">
                  <RegrelloControlledFormFieldSelect
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.DEPENDENCY,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    dataTestId={DataTestIds.START_DEPENDENCY_TASK_START_AFTER_TASK_VALUE_SELECT}
                    disabled={isDisabled}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => `${option.name}|${option.id}`}
                    isRequiredAsteriskShown={!isOptional}
                    options={startAfterDependencies ?? EMPTY_ARRAY}
                    selectRef={startAfterSelectRef}
                  />
                </div>
              ))}
            {scheduleTimeOption === ScheduleTimeValues.ON_DATE && (
              <RegrelloControlledFormFieldDate
                controllerProps={{
                  control: form.control,
                  name: ScheduleTimeKeys.DATE,
                  rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                }}
                dataTestId={DataTestIds.SCHEDULE_TIME_ON_DATE_FIELD}
                disabled={isDisabled}
                isDefaultTimeStartOfDay={isDefaultTimeStartOfDay}
                isRequiredAsteriskShown={!isOptional}
                minDate={minDueDate}
                showTimePicker={showTimePicker}
              />
            )}
            {scheduleTimeOption === ScheduleTimeValues.DAYS_TO_COMPLETE && (
              <div className="w-[14%]">
                <RegrelloControlledFormFieldNumber
                  controllerProps={{
                    control: form.control,
                    name: ScheduleTimeKeys.DAYS_TO_COMPLETE,
                    rules: {
                      ...(!isOptional ? ValidationRules.REQUIRED : undefined),
                      ...ValidationRules.INTEGER,
                      ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(1.0),
                    },
                  }}
                  dataTestId={DataTestIds.SCHEDULE_TIME_DAYS_TO_COMPLETE_FIELD}
                  disabled={isDisabled}
                  isRequiredAsteriskShown={!isOptional}
                  placeholder={ScheduleTimeDaysToCompletePlaceholder}
                />
              </div>
            )}
            {scheduleTimeOption === ScheduleTimeValues.TIME_TO_COMPLETE && (
              <div className="flex gap-2">
                <div className="w-1/3">
                  <RegrelloControlledFormFieldNumber
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.TIME_TO_COMPLETE,
                      rules: {
                        ...(!isOptional ? ValidationRules.REQUIRED : undefined),
                        ...ValidationRules.INTEGER,
                        ...ValidationRules.GREATER_THAN_OR_EQUAL_TO_LOWER_BOUND(1.0),
                      },
                    }}
                    dataTestId={DataTestIds.SCHEDULE_TIME_TIME_TO_COMPLETE_FIELD}
                    disabled={isDisabled}
                    isRequiredAsteriskShown={!isOptional}
                    placeholder="#"
                  />
                </div>

                <div className="w-30">
                  <RegrelloControlledFormFieldSelect
                    controllerProps={{
                      control: form.control,
                      name: ScheduleTimeKeys.TIME_TO_COMPLETE_UNIT,
                      rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                    }}
                    disabled={isDisabled}
                    getOptionLabel={(option) => option}
                    isFilterable={false}
                    isRequiredAsteriskShown={!isOptional}
                    options={TimeToCompleteUnits}
                  />
                </div>
              </div>
            )}

            {scheduleTimeOption === ScheduleTimeValues.FROM_CUSTOM_FIELD && (
              <div className="grow">
                <RegrelloControlledFormFieldCustomFieldInstanceSelect
                  allowedFieldPlugins={[DateFieldPlugin]}
                  controllerProps={{
                    control: form.control,
                    name: ScheduleTimeKeys.CONTROLLER_CUSTOM_FIELD,
                    rules: !isOptional ? ValidationRules.REQUIRED : undefined,
                  }}
                  dataTestId={DataTestIds.SCHEDULE_TIME_FROM_CUSTOM_FIELD_SELECT}
                  disabled={isDisabled}
                  isInactiveFieldInstancesHidden={true}
                  isRequiredAsteriskShown={!isOptional}
                  isSourceHelperTextVisible={true}
                  optionsConfig={{
                    type: "asyncLoaded",
                    dependingOnActionItemTemplateId: workflowContext.dependingOnActionItemTemplateId,
                    workflowContext:
                      workflowContext.type === "workflow"
                        ? {
                            type: "workflow",
                            workflowId: workflowContext.workflowId,
                            workflowStageId: workflowContext.workflowStageId,
                            dependingOnWorkflowStageId: undefined,
                          }
                        : {
                            type: "workflowTemplate",
                            workflowTemplateId: workflowContext.workflowTemplateId,
                            workflowStageTemplateId: workflowContext.workflowStageTemplateId,
                            dependingOnWorkflowStageTemplateId: undefined,
                          },
                  }}
                  placeholder={SelectField}
                />
              </div>
            )}
          </div>
        </RegrelloFormFieldLayout>
        {(scheduleTimeOption === ScheduleTimeValues.START_CONDITION_STAGE ||
          scheduleTimeOption === ScheduleTimeValues.START_CONDITION_WORKFLOW) && (
          <div className="pl-13">
            <RegrelloConfigureConditionsFormSection
              allowedFieldPlugins={[
                TextFieldPlugin,
                SelectFieldPlugin,
                CurrencyFieldPlugin,
                NumberFieldPlugin,
                CheckboxFieldPlugin,
                DateFieldPlugin,
                MultiSelectFieldPlugin,
                UserFieldPlugin,
                RegrelloObjectFieldPlugin,
              ]}
              conditionRows={startConditionRows}
              form={form}
              isDisabled={isDisabled}
              onConditionAdd={handleAddStartConditionRow}
              onConditionDelete={removeStartCondition}
              onConditionFieldInstanceChange={handleStartConditionFieldInstanceChange}
              onConditionFieldPropertyChange={handleStartConditionFieldPropertyChange}
              onConditionOperatorChange={handleStartConditionOperatorChange}
              onConditionStageDependencyChange={handleStartConditionStageDependencyChange}
              startAfterDependencies={startAfterDependencies}
              startConditionOption={scheduleTimeOption}
              workflowContext={workflowContext}
            />
          </div>
        )}
      </>
    );
  });
}

// TODO Conditional Branching: Potentially tie this into the custom field plugin framework. This is
// a quick patch, but would require adding a new function to the framework or refactoring
// `getEmptyValueForFrontend`.
export function areOperatorValuesCompatible(oldValue: unknown, newValue: unknown): boolean {
  return (
    (typeof oldValue === typeof newValue || newValue == null) &&
    oldValue != null &&
    isArray(oldValue) === isArray(newValue)
  );
}

function getSelectedItemProps(item: RegrelloScheduleStartAfterDependency) {
  return {
    children: item.name,
  };
}

function itemPredicate(query: string, item: RegrelloScheduleStartAfterDependency) {
  return item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
}

function renderItem(item: RegrelloScheduleStartAfterDependency) {
  return {
    key: item.id,
    text: item.name,
  };
}
