// (clewis): These rules aren't important in generated files. Ignore any violations.
/* eslint-disable */
import * as Types from '../../../generated/graphqlBaseTypes';

import { UserBaseFields } from './UserBaseFields.generated';
import { WorkflowTemplatePermissionsFields } from './WorkflowTemplatePermissionsFields.generated';
import { gql } from '@apollo/client';
export type WorkflowTemplatePrimitiveFields = { id: number, blueprintUuid: string, name: string, createdAt: string, deletedAt?: string | null, updatedAt: string, description?: string | null, isValidToUse: boolean, type: Types.WorkflowTemplateType, predictedDurationDays: number, numberOfStages: number, isCreateViaEmailEnabled: boolean, createViaEmailContactEmail?: string | null, versionNumber?: number | null, createdBy: UserBaseFields, permissions?: WorkflowTemplatePermissionsFields | null, draftVersionWorkflowTemplate?: { id: number, permissions: { canRead: boolean } } | null, variantData?: { isCompliant: boolean, standardBlueprint: { name: string } } | null };

export const WorkflowTemplatePrimitiveFields = gql`
    fragment WorkflowTemplatePrimitiveFields on WorkflowTemplate {
  id
  blueprintUuid
  name
  createdBy {
    ...UserBaseFields
  }
  createdAt
  deletedAt
  updatedAt
  description
  isValidToUse
  type
  predictedDurationDays
  numberOfStages
  permissions {
    ...WorkflowTemplatePermissionsFields
  }
  isCreateViaEmailEnabled
  createViaEmailContactEmail
  draftVersionWorkflowTemplate {
    id
    permissions {
      canRead
    }
  }
  versionNumber
  variantData {
    isCompliant
    standardBlueprint {
      name
    }
  }
}
    `;