import { ComparatorResult, EMPTY_ARRAY, EMPTY_STRING, stringComparator } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  CreateFieldInstanceValueInputs,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  PropertyDataType,
  PropertyTypeFields,
  UpdateFieldInstanceValueInputs,
  UpdateStartingConditionsInputs,
  ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloClampedText, RegrelloIcon, RegrelloLinkify } from "@regrello/ui-core";
import { Text } from "@regrello/ui-strings";
import { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { CONDITION_OPERATOR_CONFIGS_TEXT, DEFAULT_INPUT_TYPE_IF_NO_VALUE } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import { FieldInstanceBaseFields } from "../../../../types";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionStringValue,
  getRegrelloFilterDefinitionStringValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldTextMultiline } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloControlledFormFieldText } from "../../formFields/controlled/RegrelloControlledFormFieldText";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'text' field is invalid";
const WARNING_INVALID_FORM_VALUE = "Provided 'text'-field form value is not a valid string";
const ERROR_INVALID_VALUE_COUNT = "Provided 'text' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'text' field instance value must have type 'FieldInstanceValueString'";
const WARNING_UNEXPECTED_NULL_UNDEFINED_FORM_VALUE =
  "Provided 'text' field form value must not be null or undefined given the provided operator type";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'text' fields";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'text' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.STRING;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued && field.allowedValues.length === 0 && canProcessPropertyDataType(field.propertyType.dataType)
  );
}

type TextFieldPluginFrontendValue = string;
type TextFieldPluginType = CustomFieldPlugin<TextFieldPluginFrontendValue>;

const getConditionOperators: TextFieldPluginType["getConditionOperators"] = () => {
  return CONDITION_OPERATOR_CONFIGS_TEXT;
};

const renderDisplayValue: TextFieldPluginType["renderDisplayValue"] = (fieldInstance, options = {}) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);

  // In tables, we have limited vertical space, so we don't want to respect line breaks to ensure
  // all table rows are the same height. But there are certain use cases where preserving multiple
  // consecutive whitespace characters is important for data consistency (e.g., part numbers with
  // specific formatting). So, first collapse any new lines to a single space, then render with
  // consecutive whitespace preserved and no text-wrapping.
  if (options.context === "tableV2") {
    const rawString = frontendFieldInstance.value ?? EMPTY_STRING;
    const collapsedNewLines = rawString.replaceAll(/\n+/g, " ");
    return <span className="whitespace-pre">{collapsedNewLines}</span>;
  }

  return (
    <RegrelloLinkify>
      <RegrelloClampedText maxLines={options.maxLines}>
        {frontendFieldInstance.value ?? EMPTY_STRING}
      </RegrelloClampedText>
    </RegrelloLinkify>
  );
};

const sortComparator: TextFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return TextFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = String(fieldPlugin.getValueForFrontend(fieldInstance1));
  const value2 = String(fieldPlugin.getValueForFrontend(fieldInstance2));

  return stringComparator(value1, value2);
};

/**
 * Describes a custom field that holds a short or long-form text value.
 */
export const TextFieldPlugin: TextFieldPluginType = {
  uri: "com.regrello.customField.text",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.STRING)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    return {
      fieldId: field.id,
      stringValue: value ?? undefined,
      inputType,
      displayOrder,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return Text;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionStringValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    const value = filter.value ?? EMPTY_STRING;
    return getRegrelloFilterDefinitionStringValue(filter.operator, value);
  },

  getEmptyValueForFrontend: (_, defaultValue): string => {
    return defaultValue || EMPTY_STRING;
  },

  getIconName: () => {
    return "text-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null || value === EMPTY_STRING) {
      return undefined;
    }
    return value;
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.TEXT_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (value == null) {
      console.warn(WARNING_UNEXPECTED_NULL_UNDEFINED_FORM_VALUE, {
        leftFieldInstance,
        value,
      });
      return undefined;
    }
    if (typeof value !== "string") {
      console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
      return undefined;
    }

    return {
      leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
      operatorV2: operator,
      rightStringValue: value,
      rightFloatMultiValue: EMPTY_ARRAY,
      rightIntMultiValue: EMPTY_ARRAY,
      rightPartyIDMultiValue: EMPTY_ARRAY,
      rightStringMultiValue: EMPTY_ARRAY,
      rightTimeMultiValue: EMPTY_ARRAY,
    };
  },
  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): string => {
    return extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueString",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => EMPTY_STRING,
      getterIfValue: (fieldInstanceValue) => fieldInstanceValue.stringValue ?? EMPTY_STRING,
    });
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const { value } = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return value == null || value === EMPTY_STRING;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.stringValue == null) ||
      (frontendFieldInstance.value == null && proposedChange.stringValue === EMPTY_STRING) ||
      frontendFieldInstance.value === proposedChange.stringValue
    );
  },

  renderDisplayValue: renderDisplayValue,

  // eslint-disable-next-line react/display-name
  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const { operator } = props;
    const isDeleted = props.fieldInstance?.field.deletedAt != null;

    if (operator != null) {
      if (!isOperatorValid(operator)) {
        console.warn(WARNING_INVALID_OPERATOR_TYPE, {
          operator,
        });
        return undefined;
      }
      // (zstanik): Render single line text field for start conditions to avoid form fields getting
      // shifted down.
      return (
        <RegrelloControlledFormFieldText
          {...props}
          key={props.controllerProps.name}
          dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
          infoTooltipText={props.description}
          isDeleted={isDeleted}
        />
      );
    }

    return (
      <RegrelloControlledFormFieldTextMultiline
        {...props}
        key={props.controllerProps.name}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
      />
    );
  },

  renderIcon: (props): React.ReactChild => {
    return <RegrelloIcon {...props} iconName="text-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }

    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: (
          <RegrelloLinkify>
            {renderDisplayValue(fieldInstance, { context: options?.context ?? "table" })}
          </RegrelloLinkify>
        ),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendTextFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: string | null;
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendTextFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceValueString",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: null,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceValueString != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceValueString.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceValueString?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceValueString?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields ? fieldInstanceValue.sourceFieldInstanceValueString?.id : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceValueString?.inputType
        : undefined,
      value: fieldInstanceValue.stringValue ?? null,
    }),
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.EQUALS
  | ConditionOperator.NOT_EQUALS
  | ConditionOperator.CONTAINS_ANY_OF
  | ConditionOperator.CONTAINS_NONE_OF {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isValueValid(value: unknown): value is TextFieldPluginFrontendValue | null {
  return value == null || typeof value === "string";
}
