import { ComparatorResult, EMPTY_ARRAY, EMPTY_STRING } from "@regrello/core-utils";
import { DataTestIds } from "@regrello/data-test-ids-api";
import {
  ConditionOperator,
  CreateFieldInstanceValueInputs,
  FieldFields,
  FieldInstanceFields,
  FieldInstanceFieldsWithBaseValues,
  FieldInstanceValueInputType,
  PropertyDataType,
  PropertyTypeFields,
  UpdateFieldInstanceValueInputs,
  UpdateStartingConditionsInputs,
  ViewFilterFields,
} from "@regrello/graphql-api";
import { RegrelloIcon } from "@regrello/ui-core";
import { NumberMessage } from "@regrello/ui-strings";
import { FieldPath, FieldValues } from "react-hook-form";

import { CustomFieldPluginRegistrar } from "./registry/customFieldPluginRegistrar";
import { CustomFieldPlugin, CustomFieldPluginV2RenderFormFieldProps } from "./types/CustomFieldPlugin";
import { createViewColumnsFromField } from "./utils/createViewColumnsFromField";
import { CONDITION_OPERATOR_CONFIGS_NUMBER, DEFAULT_INPUT_TYPE_IF_NO_VALUE } from "./utils/customFieldConstants";
import { extractAtMostOneValueOrThrow } from "./utils/extractAtMostOneValueOrThrow";
import {
  getIsFieldInstanceFields,
  getIsFieldInstanceValueWithCrossWorkflowFields,
} from "./utils/fieldInstanceTypeguards";
import { getUpdateStartingConditionsInputsForEmptyOperators } from "./utils/getUpdateStartingConditionsInputsForEmptyOperators";
import { ValidationRules } from "../../../../constants/globalConstants";
import { FieldInstanceBaseFields } from "../../../../types";
import { numberComparator } from "../../../../utils/comparators/numberComparator";
import { getFieldInstanceId } from "../../../../utils/customFields/getFieldInstanceId";
import { getErrorMessageWithPayload } from "../../../../utils/getErrorMessageWithPayload";
import { TableCellDefaultWidths } from "../../../../utils/tableCellWidthUtils";
import {
  getRegrelloDefaultFilterDefinitionNumberValue,
  getRegrelloFilterDefinitionNumberValue,
} from "../../../molecules/tableFilterControlV2/_internal/core/regrelloFilterV2Constants";
import { RegrelloControlledFormFieldNumber } from "../../formFields/controlled/regrelloControlledFormFields";
import { RegrelloCustomFieldMultiValuePopover } from "../components/RegrelloCustomFieldMultiValuePopover";

// TODO Misc: Turn the following into warnings and handle gracefully so the app doesn't crash in
// case of field misconfiguration.
const ERROR_INVALID_FIELD = "Provided 'number' field is invalid";
const WARNING_INVALID_FORM_VALUE = "Provided 'number'-field form value is not a valid number or number string";
const ERROR_INVALID_VALUE_COUNT = "Provided 'number' field instance cannot have multiple values";
const ERROR_INVALID_VALUE_TYPE = "Provided 'number' field instance value must have type 'FieldInstanceValueInt'";
const WARNING_UNEXPECTED_EMPTY_FORM_VALUE =
  "Provided 'number' field form value must not be empty given the provided operator type";
const WARNING_INVALID_OPERATOR_TYPE = "Provided operator type is invalid for 'number' fields";
const WARNING_UNEXPECTED_DEFINED_FORM_VALUE =
  "Provided 'number' field form value must not be defined given the provided operator type";

function canProcessPropertyDataType(propertyDataType: PropertyDataType): boolean {
  return propertyDataType === PropertyDataType.INT || propertyDataType === PropertyDataType.FLOAT;
}

function canProcessField(field: FieldFields): boolean {
  return (
    !field.isMultiValued &&
    field.allowedValues.length === 0 &&
    canProcessPropertyDataType(field.propertyType.dataType) &&
    field.fieldUnit == null
  );
}

type NumberFieldPluginFrontendValue = string | null;
type NumberFieldPluginType = CustomFieldPlugin<NumberFieldPluginFrontendValue>;

const getConditionOperators: NumberFieldPluginType["getConditionOperators"] = () => {
  return CONDITION_OPERATOR_CONFIGS_NUMBER;
};

const renderDisplayValue: NumberFieldPluginType["renderDisplayValue"] = (fieldInstance) => {
  const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);

  return frontendFieldInstance.value;
};

const sortComparator: NumberFieldPluginType["sortComparator"] = (
  fieldInstance1,
  fieldInstance2,
  direction = "asc",
): ComparatorResult => {
  if (direction === "desc") {
    return NumberFieldPlugin.sortComparator(fieldInstance2, fieldInstance1, "asc");
  }

  if (fieldInstance1 == null) {
    return ComparatorResult.BEFORE;
  }

  if (fieldInstance2 == null) {
    return ComparatorResult.AFTER;
  }

  const fieldPlugin = CustomFieldPluginRegistrar.getPluginForField(fieldInstance1.field);

  const value1 = Number(fieldPlugin.getValueForFrontend(fieldInstance1));
  const value2 = Number(fieldPlugin.getValueForFrontend(fieldInstance2));

  return numberComparator(value1, value2);
};

/**
 * Describes a custom field that holds a number value (whether an integer or a float).
 */
export const NumberFieldPlugin: NumberFieldPluginType = {
  uri: "com.regrello.customField.number",
  version: "1.0.0",

  canProcessField: (field: FieldFields): boolean => {
    return canProcessField(field);
  },

  canProcessFieldInstance: (fieldInstance: FieldInstanceBaseFields): boolean => {
    try {
      translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
      return true;
    } catch (_error) {
      return false;
    }
  },

  canProcessPropertyDataType,

  findPropertyTypeIdFromLoadedPropertyTypeIds: (propertyTypes: PropertyTypeFields[]): number | undefined => {
    return propertyTypes.find((propertyType) => propertyType.dataType === PropertyDataType.INT)?.id;
  },

  getColumnsForTable: createViewColumnsFromField,

  getConditionOperators,

  getCreateFieldInstanceValueInputsFromFormValue: (
    field: FieldFields,
    inputType: FieldInstanceValueInputType,
    value: unknown,
    displayOrder?: number,
  ): CreateFieldInstanceValueInputs => {
    if (!isValueValid(value)) {
      throw new Error(getErrorMessageWithPayload(WARNING_INVALID_FORM_VALUE, { field, inputType, value }));
    }
    return {
      fieldId: field.id,
      intValue:
        value == null || value === EMPTY_STRING
          ? undefined
          : !Number.isNaN(Number.parseInt(value, 10))
            ? Number.parseInt(value, 10)
            : undefined,
      inputType,
      displayOrder,
    };
  },

  getCrossWorkflowSinksFieldInstanceIds: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number[] => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSinksFieldInstanceIds;
  },

  getCrossWorkflowSourceFieldInstanceIdFromValue: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.crossWorkflowSourceFieldInstanceId;
  },

  getFieldDisplayName: (): string => {
    return NumberMessage;
  },

  getFilterDefinition: (_field: FieldFields) => {
    return getRegrelloDefaultFilterDefinitionNumberValue();
  },

  getFilterDefinitionWithValues: (_field: FieldFields, filter: ViewFilterFields) => {
    if (filter.value == null) {
      return undefined;
    }
    const value = Number.parseFloat(filter.value);
    return getRegrelloFilterDefinitionNumberValue(filter.operator, value);
  },

  getEmptyValueForFrontend: (_, defaultValue): string | null => {
    return defaultValue ? Number.parseInt(defaultValue, 10).toString() : null;
  },

  getIconName: () => {
    return "number-field";
  },

  getNameTemplateDisplayValueFromFormValue: (value) => {
    if (!isValueValid(value) || value == null || value === EMPTY_STRING) {
      return undefined;
    }
    return value;
  },

  getPreferredHomeTableColumnWidth: () => {
    return TableCellDefaultWidths.NUMBER_CELL;
  },

  getSourceFieldInstance: (_fieldInstance: FieldInstanceFields): FieldInstanceFields | undefined => {
    return undefined;
  },

  getSourceFieldInstanceId: (fieldInstance: FieldInstanceFields): number | undefined => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.sourceFieldInstanceId;
  },

  getSourceFieldInstanceInputType: (
    fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
  ): FieldInstanceValueInputType | undefined => {
    const sourceValue = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return sourceValue.sourceFieldInstanceInputType;
  },

  getUpdateStartingConditionsInputsFromFormValues: (
    leftFieldInstance: FieldInstanceFields,
    value: unknown,
    operator: ConditionOperator,
  ): UpdateStartingConditionsInputs | undefined => {
    if (!isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        leftFieldInstance,
        operator,
      });
      return undefined;
    }

    if (operator === ConditionOperator.EMPTY || operator === ConditionOperator.NOT_EMPTY) {
      if (value != null) {
        console.warn(WARNING_UNEXPECTED_DEFINED_FORM_VALUE, {
          leftFieldInstance,
          value,
        });
      }
      return getUpdateStartingConditionsInputsForEmptyOperators(getFieldInstanceId(leftFieldInstance), operator);
    }

    if (value == null || value === EMPTY_STRING || (Array.isArray(value) && value.length === 0)) {
      console.warn(WARNING_UNEXPECTED_EMPTY_FORM_VALUE, {
        leftFieldInstance,
        value,
      });
      return undefined;
    }

    if (operator === ConditionOperator.BETWEEN) {
      // (zstanik): For the `BETWEEN` operator, the value is expected to be an array of 2
      // stringified numbers: a lower bound and upper bound.
      if (!Array.isArray(value) || value.length !== 2 || value.some((str) => Number.isNaN(Number.parseInt(str, 10)))) {
        console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
        return undefined;
      }

      return {
        leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
        operatorV2: operator,
        rightIntMultiValue: value.map((str) => Number.parseInt(str, 10)),
        rightFloatMultiValue: EMPTY_ARRAY,
        rightPartyIDMultiValue: EMPTY_ARRAY,
        rightStringMultiValue: EMPTY_ARRAY,
        rightTimeMultiValue: EMPTY_ARRAY,
      };
    } else {
      // (zstanik): For all other operators, the value is expected to be a stringified number.
      if (typeof value !== "string" || (value.length > 0 && Number.isNaN(Number.parseInt(value, 10)))) {
        console.warn(WARNING_INVALID_FORM_VALUE, { leftFieldInstance, value });
        return undefined;
      }

      return {
        leftFieldInstanceValueID: getFieldInstanceId(leftFieldInstance),
        operatorV2: operator,
        rightIntValue: Number.parseInt(value, 10),
        rightFloatMultiValue: EMPTY_ARRAY,
        rightIntMultiValue: EMPTY_ARRAY,
        rightPartyIDMultiValue: EMPTY_ARRAY,
        rightStringMultiValue: EMPTY_ARRAY,
        rightTimeMultiValue: EMPTY_ARRAY,
      };
    }
  },

  // (hchen): Currently this is only used for handling the edge case when flipping the field
  // instance input type from OPTIONAL to REQUESTED (i.e. required) when the a previously OPTIONAL
  // field is set as a native field on a future task. It's not necessary to implement for field
  // types other than date and user, since we only support those as native field now.
  getUpdateFieldInstanceValueInputsFromFieldInstance: (
    _fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
  ): UpdateFieldInstanceValueInputs[] => {
    throw new Error("not implmented yet");
  },

  getValueForFrontend: (fieldInstance: FieldInstanceFields | FieldInstanceBaseFields): string | null => {
    return extractAtMostOneValueOrThrow({
      fieldInstance,
      fieldInstanceValueTypeName: "FieldInstanceValueInt",
      errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
      errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
      getterIfNoValue: () => "",
      getterIfValue: (fieldInstanceValue) => {
        const value = fieldInstanceValue.intValue;
        if (value == null) {
          return "";
        }
        if (Number.isNaN(value)) {
          return "";
        }
        return value.toString();
      },
    });
  },

  isCreateAndEditAllowed: true,

  isFeatureFlagEnabled: (): boolean => {
    return true;
  },

  isFieldInstanceEmpty: (fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    return frontendFieldInstance.value == null;
  },

  isFieldInstanceValueUnchanged: (
    fieldInstance: FieldInstanceFields | FieldInstanceFieldsWithBaseValues,
    proposedChange: CreateFieldInstanceValueInputs,
  ): boolean => {
    const frontendFieldInstance = translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(fieldInstance);
    if (frontendFieldInstance.sourceFieldInstanceId !== proposedChange.sourceFieldInstanceValueId) {
      return false;
    }

    if (frontendFieldInstance.inputType !== proposedChange.inputType) {
      return false;
    }

    return (
      (frontendFieldInstance.value == null && proposedChange.intValue == null) ||
      frontendFieldInstance.value === proposedChange.intValue?.toString()
    );
  },

  renderDisplayValue: renderDisplayValue,

  // eslint-disable-next-line react/display-name
  renderFormField: <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
    field: FieldFields,
    props: CustomFieldPluginV2RenderFormFieldProps<TFieldValues, TName>,
  ): React.ReactNode => {
    if (!canProcessField(field)) {
      throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
    }
    const isDeleted = props.fieldInstance?.field.deletedAt != null;

    const { operator } = props;
    if (operator != null && !isOperatorValid(operator)) {
      console.warn(WARNING_INVALID_OPERATOR_TYPE, {
        field,
        operator,
      });
      return undefined;
    }

    return (
      <RegrelloControlledFormFieldNumber
        {...props}
        key={props.controllerProps.name}
        controllerProps={{
          ...props.controllerProps,
          rules: {
            ...props.controllerProps.rules,
            ...ValidationRules.INTEGER,
          },
        }}
        dataTestId={DataTestIds.CUSTOM_FIELD_VALUE_INPUT}
        infoTooltipText={props.description}
        isDeleted={isDeleted}
      />
    );
  },

  renderIcon: (props): React.ReactChild => {
    return <RegrelloIcon {...props} iconName="number-field" />;
  },

  renderMultipleDisplayValuesForDataGrid: (fieldInstances, options) => {
    if (fieldInstances.length === 0) {
      return null;
    }
    if (fieldInstances.length === 1) {
      return renderDisplayValue(fieldInstances[0], { context: options?.context ?? "table" });
    }

    const instancesWithSource = fieldInstances.map((fieldInstance) => {
      return {
        content: renderDisplayValue(fieldInstance, { context: options?.context ?? "table" }),
        workflowName: fieldInstance.workflow?.name,
        stageName: fieldInstance.actionItem?.workflowReference?.stageName,
        taskName: fieldInstance.actionItem?.name,
      };
    });

    return <RegrelloCustomFieldMultiValuePopover instancesWithSource={instancesWithSource} />;
  },

  sortComparator,
};

interface FrontendNumberFieldInstance {
  name: string;
  inputType: FieldInstanceValueInputType;
  crossWorkflowSinksFieldInstanceIds: number[];
  crossWorkflowSourceFieldInstanceId: number | undefined;
  sinksFieldInstanceIds: number[];
  sourceFieldInstanceId: number | undefined;
  sourceFieldInstanceInputType: FieldInstanceValueInputType | undefined;
  value: string | null;
}

function translateGraphQlFieldInstanceToFrontendFieldInstanceOrThrow(
  fieldInstance: FieldInstanceFields | FieldInstanceBaseFields,
): FrontendNumberFieldInstance {
  const { field } = fieldInstance;

  if (!canProcessField(field)) {
    throw new Error(getErrorMessageWithPayload(ERROR_INVALID_FIELD, { field }));
  }

  const isFieldInstanceFields = getIsFieldInstanceFields(fieldInstance);

  return extractAtMostOneValueOrThrow({
    fieldInstance,
    fieldInstanceValueTypeName: "FieldInstanceValueInt",
    errorMessageIfMultipleValues: ERROR_INVALID_VALUE_COUNT,
    errorMessageIfWrongValueType: ERROR_INVALID_VALUE_TYPE,
    getterIfNoValue: () => ({
      name: field.name,
      inputType: DEFAULT_INPUT_TYPE_IF_NO_VALUE,
      crossWorkflowSinksFieldInstanceIds: EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: undefined,
      sinksFieldInstanceIds: EMPTY_ARRAY,
      sourceFieldInstanceId: undefined,
      sourceFieldInstanceInputType: undefined,
      value: null,
    }),
    getterIfValue: (fieldInstanceValue) => ({
      name: field.name,
      inputType: fieldInstanceValue.inputType,
      crossWorkflowSinksFieldInstanceIds:
        getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue) &&
        fieldInstanceValue.crossWorkflowSinksFieldInstanceValueInt != null
          ? fieldInstanceValue.crossWorkflowSinksFieldInstanceValueInt.map((value) => value.id)
          : EMPTY_ARRAY,
      crossWorkflowSourceFieldInstanceId: getIsFieldInstanceValueWithCrossWorkflowFields(fieldInstanceValue)
        ? fieldInstanceValue.crossWorkflowSourceFieldInstanceValueInt?.id
        : undefined,
      sinksFieldInstanceIds: isFieldInstanceFields
        ? fieldInstanceValue.sinksFieldInstanceValueInt?.map(({ id }) => id)
        : EMPTY_ARRAY,
      sourceFieldInstanceId: isFieldInstanceFields ? fieldInstanceValue.sourceFieldInstanceValueInt?.id : undefined,
      sourceFieldInstanceInputType: isFieldInstanceFields
        ? fieldInstanceValue.sourceFieldInstanceValueInt?.inputType
        : undefined,
      value:
        fieldInstanceValue.intValue == null || Number.isNaN(fieldInstanceValue.intValue)
          ? null
          : fieldInstanceValue.intValue.toString(),
    }),
  });
}

function isOperatorValid(
  operator: ConditionOperator,
): operator is
  | ConditionOperator.EMPTY
  | ConditionOperator.NOT_EMPTY
  | ConditionOperator.GREATER_THAN_OR_EQUALS
  | ConditionOperator.LESS_THAN_OR_EQUALS
  | ConditionOperator.EQUALS
  | ConditionOperator.NOT_EQUALS
  | ConditionOperator.BETWEEN {
  return getConditionOperators().find((stageStartOperator) => stageStartOperator.operator === operator) != null;
}

function isValueValid(value: unknown): value is NumberFieldPluginFrontendValue {
  return (
    value == null || value === EMPTY_STRING || (typeof value === "string" && !Number.isNaN(Number.parseInt(value, 10)))
  );
}
